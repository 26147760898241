export const placeTypes = {
    landmark: 'Landmarks',
    school: 'International Schools & Kindergartens',
    sport: 'Sport Centers & Routes',
    park: 'Parks & Playgrounds',
    restaurant: 'Restaurants & Coffee shops',
    supermarket: 'Supermarkets & Groceries',
    commercial: 'Commercial Centers',
    // tram: 'Tram Stations',
    misc: 'Miscellaneous',
    // culture: 'Culture Institutions',
    // carRental: 'Car Rentals Services',
    hotels: 'Hotels/Hostels',
    medical: 'Health Care/Hospitals',
    government: 'Government Authorities/Offices',
    // group: 'Group',
    ngo: 'Diplomatic Missions and International Organizations',
    other: 'Staff Members',
    entertainment: 'Entertainment Centers',
    routes: 'Routes',
    lines: 'Lines',
    specific: 'Specific',
    parkingLot: 'Parking lot',
};
