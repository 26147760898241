import { isEmpty } from 'lodash';
import {
    TOGGLE_MAP,
    SET_MAP_MARKER,
    HIGHLIGHT_MARKER,
    SELECT_DISTANCE_POINT,
    RESET_DISTANCE_CALCULATE,
    SWAP_DISTANCE_LOCATIONS,
    TOGGLE_PINS,
    SET_ROUTE_TYPE,
    BUTTONS_STATE,
    SET_MAP_BUTTONS_STATE,
    SET_MAP_FULLSCREEN,
    SET_MAP_INFO_WINDOW,
    SHOW_SEPARATION_LINE,
    TOGGLE_LIVE_LOCATION,
} from 'constants';

const initial = {
    zoom: 15,
    showMap: false,
    highlightMarker: {},
    show: true,
    routeType: 'DRIVING',
    buttonsState: BUTTONS_STATE.NONE,
    fullscreen: false,
    infoWindow: undefined,
};

const app = (state = initial, action) => {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case TOGGLE_MAP:
            newState.showMap = !newState.showMap;
            return newState;
        case SET_MAP_MARKER:
            newState.marker = action.marker;
            return newState;
        case HIGHLIGHT_MARKER:
            if (
                !isEmpty(action.marker?.geoPoint) ||
                !isEmpty(action.marker?.location)
            ) {
                newState.highlightMarker = action.marker;
            }
            return newState;
        case SELECT_DISTANCE_POINT:
            newState[action.direction] = action.point;
            return newState;
        case SWAP_DISTANCE_LOCATIONS:
            const { origin, destination } = newState;
            newState.origin = destination;
            newState.destination = origin;
            return newState;
        case SET_ROUTE_TYPE:
            newState.routeType = action.routeType;
            return newState;
        case RESET_DISTANCE_CALCULATE:
            newState.origin = undefined;
            newState.destination = undefined;
            newState.routeType = 'DRIVING';
            return newState;
        case TOGGLE_PINS:
            newState.show = !state.show;
            return newState;
        case SET_MAP_BUTTONS_STATE:
            newState.buttonsState = action.buttonsState;
            return newState;
        case SET_MAP_FULLSCREEN:
            newState.fullscreen = action.fullscreen;
            return newState;
        case SET_MAP_INFO_WINDOW:
            newState.infoWindow = action.info;
            return newState;
        case SHOW_SEPARATION_LINE:
            newState.showSeparationLine = !newState.showSeparationLine;
            return newState;
        case TOGGLE_LIVE_LOCATION:
            newState.liveLocation = !newState.liveLocation;
            return newState;
        default:
            return state || {};
    }
};

export default app;
