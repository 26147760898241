import Header from 'components/header';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import { isMobile } from 'react-device-detect';
import { SideMenu } from 'components/header/SideMenu';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from 'actions/app';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

const AppLayout = ({ children, headless }) => {
    const dispatch = useDispatch();
    const { showSideBar } = useSelector(state => state.app);
    const { pathname } = useLocation();
    const isHomepage = pathname === '/';

    const toggleSideBar = () => {
        dispatch(toggleSidebar());
    };

    return (
        <>
            {!headless ? <Header /> : undefined}
            <main className={`site-content ${isHomepage ? 'home' : ''}`}>
                {children}
                {isMobile && (
                    <Drawer
                        open={showSideBar}
                        onClose={toggleSideBar}
                        direction="right"
                        style={{ width: '80%' }}>
                        <SideMenu />
                    </Drawer>
                )}
            </main>
            <Footer />
        </>
    );
};

export default AppLayout;
