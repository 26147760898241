import React, { useState, memo, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'reflexbox';
import { theme } from 'index';
import SwipeableViews from 'react-swipeable-views';
import { getChildByTypeDeep } from 'react-nanny';

export const MenuItem = ({ icon, label, selected, onClick, ...rest }) => (
    <Flex alignItems="center" px={2} py={3} onClick={onClick} {...rest}>
        <Box mr={2}>{icon}</Box>
        <Box px={2} sx={{ whiteSpace: 'nowrap' }}>
            {label}
        </Box>
    </Flex>
);

const ActionMenu = ({ buttonIcon, items, badge, ...rest }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentMenu, setCurrentMenu] = useState(0);

    const ref = useRef(null);

    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleClose = () => {
        setIsOpen(false);
        setCurrentMenu(0);
    };

    const getItems = (group, idx) => {
        const { items, label } = group;
        return (
            <Box key={idx}>
                <Flex bg="#EFEFEF" px={3} py={2} sx={{ borderRadius: '4px' }}>
                    {idx > 0 && (
                        <Box
                            className="material-icons"
                            onClick={() => setCurrentMenu(0)}
                            sx={{ ml: -2, mr: 2, color: theme.gold }}>
                            chevron_left
                        </Box>
                    )}
                    <Box
                        color="#9B9B9B"
                        fontSize="12px"
                        fontWeight="700"
                        lineHeight="22px"
                        flexGrow={1}
                        onClick={() => (idx > 0 ? setCurrentMenu(0) : null)}>
                        {label}
                    </Box>
                    <Box
                        className="material-icons"
                        color="#606060"
                        onClick={() => {
                            setIsOpen(false);
                            setCurrentMenu(0); //rest the swipe views to the first one
                        }}>
                        close
                    </Box>
                </Flex>
                {items.map((child, i) => {
                    if (child.custom)
                        return React.cloneElement(child.custom, {
                            key: i,
                            onClick: () => {
                                if (child.custom.props.onClick) {
                                    child.custom.props.onClick();
                                    handleClose();
                                }
                            },
                        });
                    return (
                        <MenuItem
                            key={i}
                            icon={child.icon}
                            label={child.label}
                            onClick={() => {
                                if (child.onClick) {
                                    child.onClick();
                                    handleClose();
                                }
                                if (child.index) setCurrentMenu(child.index);
                            }}
                        />
                    );
                })}
            </Box>
        );
    };

    return (
        <Box ref={ref}>
            {isOpen && (
                <Box
                    sx={{
                        bg: '#FFF',
                        borderRadius: '8px',
                        padding: '5px',
                        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
                        position: 'absolute',
                        bottom: 58,
                        right: 10,
                        minWidth: 200,
                        maxWidth: 240,
                    }}>
                    <SwipeableViews index={currentMenu} animateHeight>
                        {items.map((group, i) =>
                            getItems(group, group.index || i)
                        )}
                    </SwipeableViews>
                </Box>
            )}
            <Flex
                sx={{
                    bg: theme.gold,
                    borderRadius: '100px',
                    height: 48,
                    width: 48,
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0px 4px 8px 0px #AFAFAF',
                }}
                onClick={toggleMenu}>
                {badge ? (
                    <Box
                        sx={{
                            bg: 'red',
                            color: '#FFF',
                            fontSize: '10px',
                            width: '20px',
                            height: '18px',
                            borderRadius: '50%',
                            lineHeight: '18px',
                            textAlign: 'center',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            mt: -1,
                            mr: -1,
                        }}>
                        {badge}
                    </Box>
                ) : undefined}
                <Box mt={'-3px'}>{buttonIcon}</Box>
            </Flex>
        </Box>
    );
};

export default memo(ActionMenu);
