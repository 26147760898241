import React from 'react';
import { Box, Flex } from 'reflexbox';
import { useDispatch } from 'react-redux';
import { messagesReply } from 'actions';
import MessagesDisplay from '../../../components/customMap/editComponents/MessagesDisplay';
import { DynamicHeightInput } from '../../../components/common/DynamicHeightInput';
import { theme } from 'index';
import { isMobile } from 'react-device-detect';

const PropertyPopupMessages = ({ show, property, allowSend, onClose }) => {
    const ref = React.useRef(null);

    const dispatch = useDispatch();

    const sendMessage = message => {
        dispatch(messagesReply(message, property.id));
    };

    return (
        <Flex
            width={isMobile ? '100%' : 270}
            pt={3}
            flexDirection="column"
            maxHeight={isMobile ? '100%' : 630}
            height="100%">
            <Flex
                mb={3}
                style={{
                    borderBottom: onClose ? `1px solid ${theme.gold}` : 'none',
                }}>
                {onClose ? (
                    <Box
                        className="material-icons"
                        color={theme.gold}
                        onClick={onClose}
                        px={2}>
                        chevron_left
                    </Box>
                ) : undefined}
                <Box fontWeight="500" fontSize="18px" pl={2} height={50}>
                    Messages
                </Box>
            </Flex>
            <Box flexGrow={1} overflowY="auto" ref={ref} px={isMobile ? 3 : 0}>
                <MessagesDisplay property={property} parentRef={ref} />
            </Box>
            <Box mt="auto">
                {allowSend ? (
                    <DynamicHeightInput
                        placeholder={
                            isMobile ? 'Send a message' : 'Reply to Tarek...'
                        }
                        onSend={sendMessage}
                    />
                ) : undefined}
            </Box>
        </Flex>
    );
};

export default PropertyPopupMessages;
