import { setMapZoom } from 'actions/map';
import React from 'react';
import { createPortal } from 'react-dom';
import { Box } from 'reflexbox';

const MobilePopup = ({ children }) => {
    return createPortal(
        <Box
            id="mobile-popup"
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: '#FFFFFF',
                overflow: 'auto',
                zIndex: 99,
            }}>
            {children}
        </Box>,
        document.body
    );
};

export default MobilePopup;
