import React from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { NavLink } from 'react-router-dom';

export const StyledHeader = styled(Flex)`
    align-items: center;
    background: ${({ transparent }) => (transparent ? 'transparent' : '#FFF')};
    padding: 10px 0px;
    z-index: ${({ fullscreen }) => (fullscreen ? 1 : 11)};
    position: fixed;
    width: 100%;
    height: 75px;
    top: 0;
    box-shadow: ${({ transparent, noShadow }) =>
        transparent || noShadow ? 'none' : '0px 2px 6px #0000001A'};

    border-top: 4px solid
        ${({ transparent, theme }) =>
            transparent ? 'transparent' : theme.gold};

    border-bottom: 2px solid
        ${({ withBottomBorder, theme }) =>
            withBottomBorder ? theme.gold : 'transparent'};

    transition: all 0.25s linear;
`;

export const HeaderLink = styled(NavLink)`
    color: ${({ transparent, theme }) => (transparent ? '#FFF' : theme.grey)};
    font-size: 12px !important;
    font-weight: bold !important;
    text-decoration: none;
    &:hover {
        color: ${({ transparent, theme }) =>
            transparent ? '#FFF' : theme.gold};
    }
`;
