import { trimEnd } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex } from 'reflexbox';
import { Tag } from '../StyledComponents';
import PropAttributes from './PropAttributes';

import { Flagged } from 'components/common/Details';
import Availability from 'components/common/tile/Availability';
import { RELEVANCE } from 'components/customMap/constants';
import { theme } from 'index';
import { NavLink } from 'react-router-dom';
import { ROUTE_CONST } from 'Routes';
import PropertyAttribComments from './PropertyAttribComments';
import PropertyPopupMessages from './PropertyPopupMessages';
import { isMobile, MobileView } from 'react-device-detect';
import InterestInProperty from 'components/customMap/viewComponents/InterestInProperty';

const PropertyContentMobile = props => {
    const {
        id,
        title,
        masterPhoto,
        photo,
        intro,
        description,
        summary,
        propId,
        site,
        tour,
        geoPoint,
        location,
        highlight,
        onClose,
        isShowing,
        noDistance,
        statuses,
        recommended,
        customerRelevance,
        customerComment,
        note,
        furnitureStatus,
        status, // this is for availability
        ...rest
    } = props;
    const {
        object: {
            messages,
            messagesEnabled,
            hideNotInterested,
            propertiesMeta,
            ...otherObjectProps
        },
    } = useSelector(state => state.customMap);

    const isCustomMap = otherObjectProps?.id != null;

    const propertyMessages = messages?.filter(m => m.property === id);
    const propertyMeta = propertiesMeta?.find(m => m.propertyId === id);

    const [showMessages, setShowMessages] = React.useState(
        isMobile ? false : propertyMessages?.length > 0
    );

    return (
        <Box height="100%" style={{ position: 'relative' }}>
            <Flex height={1}>
                <Box style={{ position: 'relative' }} flexGrow={1}>
                    <Flex
                        px={3}
                        height={75}
                        alignItems="center"
                        style={{ borderBottom: `2px solid ${theme.gold}` }}>
                        <Box
                            className="material-icons"
                            color={theme.gold}
                            onClick={onClose}
                            mr={2}
                            fontSize="30px"
                            minWidth="auto"
                            ml="-6px">
                            chevron_left
                        </Box>
                        <Box
                            color="$606060"
                            fontSize="15px"
                            fontWeight="700"
                            lineHeight="18px"
                            flexGrow={1}>
                            <Box>{title}</Box>
                        </Box>
                        <NavLink to={ROUTE_CONST.HOME}>
                            <Box
                                as="img"
                                src={`${process.env.PUBLIC_URL}/images/JR_color.png`}
                                height={38}
                                width={32}
                                mr={2}
                            />
                        </NavLink>
                    </Flex>
                    <Box id="info_content" px={3} pt={3}>
                        <Box style={{ position: 'relative' }}>
                            <Flex
                                mb={13}
                                mt={2}
                                ml={2}
                                style={{ position: 'absolute' }}>
                                {propertyMeta?.categories?.map((s, i) => (
                                    <Tag key={i}>{s}</Tag>
                                ))}
                                {customerRelevance ? (
                                    <Tag>
                                        {
                                            RELEVANCE.find(
                                                v => v.id === customerRelevance
                                            ).title
                                        }
                                    </Tag>
                                ) : undefined}
                            </Flex>
                            {masterPhoto ? (
                                <a href={`/properties/${id}`} target="_blank">
                                    <Box
                                        height={[274, 174]}
                                        style={{
                                            backgroundImage: `url(${trimEnd(
                                                masterPhoto
                                            )}=s640)`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            borderRadius: `10px 10px 0px 0px`,
                                        }}
                                    />
                                </a>
                            ) : undefined}
                            <Box
                                href={`/properties/${id}`}
                                as="a"
                                target="_blank"
                                padding="4px 10px"
                                fontSize="10px"
                                bg="#FFCC07"
                                color="#606060"
                                style={{
                                    borderRadius: '50px',
                                    display: 'block',
                                    position: 'absolute',
                                    bottom: 10,
                                    left: 10,
                                }}>
                                <Flex alignItems="center">
                                    <Box color="#303030" fontWeight="700">
                                        View Full Details
                                    </Box>
                                    <Box
                                        className="material-icons"
                                        fontSize="15px"
                                        ml={10}>
                                        arrow_outward
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                        <Flex
                            bg="#E8E8E8"
                            justifyContent="space-between"
                            alignItems="center"
                            p={2}>
                            <Availability
                                {...rest}
                                status={status}
                                horizontal
                            />
                            <Box
                                style={{
                                    background: '#838383',
                                    borderRadius: 20,
                                    color: '#c7c7c7',
                                    fontSize: '12px',
                                    padding: '2px 6px',
                                }}>{`ID${propId}`}</Box>
                        </Flex>
                    </Box>
                    <Box px={3} flexGrow={1}>
                        <Box mb={3}>
                            <PropAttributes
                                {...rest}
                                status={status}
                                furnitureStatus={furnitureStatus}
                            />
                        </Box>
                        {summary && (
                            <Box pb={4}>
                                <Box>
                                    {summary}{' '}
                                    <NavLink
                                        to={`${ROUTE_CONST.PROPERTIES}/${id}`}
                                        target="_blank">
                                        [more]
                                    </NavLink>
                                </Box>
                            </Box>
                        )}
                        {isCustomMap ? (
                            <PropertyAttribComments
                                {...propertyMeta}
                                furnitureStatus={furnitureStatus}
                                availabilityStatus={status}
                            />
                        ) : undefined}

                        <Flex justifyContent="flex-end" mt={-3} mb={1}>
                            {recommended ? (
                                <Flagged ml={20}>
                                    <Box fontSize="12px" lineHeight="15px">
                                        HIGHLY
                                    </Box>
                                    <Box fontSize="12px" lineHeight="15px">
                                        RECOMMENDED
                                    </Box>
                                </Flagged>
                            ) : undefined}
                        </Flex>
                        {isCustomMap ? (
                            <InterestInProperty propertyId={id} />
                        ) : undefined}
                        {isCustomMap ? (
                            <Box
                                bg={theme.gold}
                                color="#FFF"
                                py={1}
                                px={3}
                                style={{
                                    position: 'fixed',
                                    borderRadius: 40,
                                    zIndex: 1,
                                    boxShadow:
                                        '0px 3px 10px 2px rgb(165 165 165 / 50%)',
                                    right: 20,
                                    bottom: 30,
                                }}
                                onClick={() => {
                                    setShowMessages(!showMessages);
                                }}>
                                Messages
                            </Box>
                        ) : undefined}
                    </Box>
                </Box>
                {showMessages ? (
                    <Box
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: '#FFF',
                            zIndex: 99,
                        }}
                        overflow="hidden">
                        <PropertyPopupMessages
                            property={props}
                            show={showMessages}
                            allowSend={messagesEnabled}
                            onClose={() => setShowMessages(false)}
                        />
                    </Box>
                ) : undefined}
            </Flex>
        </Box>
    );
};

export default PropertyContentMobile;
