import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MapWrapper } from './MapWrapper';
import MapClose from './MapClose';
import PlacesMap from './PlacesMap';
import { NEIGHBORHOOD, PROPERTY } from './PlacesMenu';
import { isMobile } from 'react-device-detect';

function Map() {
    const {
        map: { showMap },
    } = useSelector(state => state);

    useEffect(() => {
        document.body.style.overflow = showMap ? 'hidden' : 'auto';
    }, [showMap]);

    return showMap ? (
        <MapWrapper>
            <MapClose />
            <PlacesMap
                allowFullScreen={false}
                isPropertiesSelected
                globalMap
                selected={[PROPERTY, NEIGHBORHOOD]}
            />
        </MapWrapper>
    ) : null;
}

export default Map;
