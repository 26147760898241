import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'reflexbox';
import moment from 'moment';
import { Label } from './Attributes';
import Tippy from '@tippyjs/react';
import { isMobile } from 'react-device-detect';

const dotStyle = ({ status }) => {
    switch (status) {
        case 'available':
            return '#19A99D';

        case 'unavailable':
            return '#CD404A';

        case 'soon':
            return '#CB9A22';

        default:
            return '#707070';
    }
};
const Dot = styled.div`
    background: ${dotStyle};
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin: ${({ isList, large, soon }) =>
        isList
            ? large
                ? `8px 0px ${soon ? 5 : 15}px`
                : '5px 0px 6px'
            : '5px 0px 4px'};
`;

export const STATUSES = {
    available: 'Available',
    unavailable: 'Unavailable',
    soon: 'Available soon',
};

function Availability({
    status,
    availableOn,
    isList,
    withTooltip,
    large,
    horizontal,
}) {
    if (!status) return null;

    return (
        <Flex
            mr={4}
            alignItems={horizontal ? 'center' : 'flex-start'}
            flexDirection={horizontal ? 'row' : 'column'}
            style={{ position: 'relative' }}>
            <Dot
                status={status}
                isList={isList}
                large={large}
                soon={status === 'soon'}
            />
            <Label isList={isList} horizontal={horizontal}>
                {STATUSES[status]}
                {status === 'soon' && availableOn ? (
                    withTooltip ? (
                        <Tippy
                            content={
                                <Box p={2}>{`Will be available on ${moment(
                                    availableOn
                                ).format('MMM DD, YYYY')}`}</Box>
                            }
                            interactive={true}
                            maxWidth="300px"
                            trigger="click">
                            <Box
                                fontSize="15px"
                                style={{ cursor: 'pointer' }}
                                ml={1}
                                className="material-icons-outlined">
                                info
                            </Box>
                        </Tippy>
                    ) : (
                        <Box
                            mt={horizontal ? -19 : 0}
                            ml={horizontal ? 100 : 0}
                            style={{
                                position: !isList ? 'absolute' : 'block',
                            }}>{` (${moment(availableOn).format(
                            'DD/MM/YYYY'
                        )})`}</Box>
                    )
                ) : undefined}
                {/*status === 'soon' && availableOn ? (
                    <Box fontSize="0.9em" style={{ position: 'absolutes' }}>
                        ({moment(availableOn).format('MMM DD, YYYY')})
                    </Box>
                ) : undefined*/}
            </Label>
        </Flex>
    );
}

export default Availability;
