import React, { useState, useEffect, useContext } from 'react';
import { Flex, Box } from 'reflexbox';
import { useSelector } from 'react-redux';
import { BUTTONS_STATE } from 'constants';
import {
    markMessagesAsRead,
    setMapButtonsState,
    toggleSeparationLine,
} from 'actions';
import { useDispatch } from 'react-redux';
import Checkbox from '../common/Checkbox';
import CheckboxTree from './Checkboxtree';
import PlacesSearch from 'elements/map/PlacesSearch';
import {
    SidePanel,
    SidePanelContent,
    SidePanelTitle,
    MenuWrapper,
    DropMenu,
    MenuItemWrapper,
    UnreadFlag,
} from './Styled';
import DistanceCalculator from '../../elements/map/distanceCalculator';
import MessagesViewOnMap from '../customMap/editComponents/MessagesViewOnMap';
import MapContext from '../../elements/map/MapContext';
import { BrowserView, isBrowser, MobileView } from 'react-device-detect';
import MobilePopup from 'elements/mobile/MobilePopup';
import { MobilePopupHeader } from 'elements/mobile/MobilePopupHeader';

const imagePath = `${process.env.PUBLIC_URL}/images/mapResources/mapMenu/`;

const MapActionsIcon = <img src={`${imagePath}map_actions_icon.png`} />;
const LayerIcon = <img src={`${imagePath}layers_icon.png`} />;
const SearchIcon = <img src={`${imagePath}search_icon.png`} />;
const DistanceIcon = <img src={`${imagePath}calculate_distance_icon.png`} />;
const MessagesIcon = <img src={`${imagePath}messages_icon.png`} />;

const MenuItem = ({ icon, caption, arrow, children, ...props }) => (
    <MenuItemWrapper {...props}>
        <Flex
            width="32px"
            height="32px"
            alignItems="center"
            style={{ pointerEvents: 'none' }}>
            {icon}
        </Flex>
        {caption ? (
            <Box
                flexGrow={1}
                px="14px"
                color="#707070"
                fontWeight="bold"
                style={{ pointerEvents: 'none' }}>
                {caption}
            </Box>
        ) : undefined}
        {arrow ? <Box>{arrow}</Box> : undefined}
        {children}
    </MenuItemWrapper>
);
export const MapMenu = ({
    globalMap,
    nodes,
    selected,
    setSelected,
    isPropertiesSelected,
    noDistance,
    formControl,
}) => {
    const {
        customMap: {
            object: { messages = [], id, messagesEnabled },
            messagesFullscreen,
        },
        users: { user },
        map: { buttonsState, showSeparationLine },
    } = useSelector(state => state);
    const { mapWrapperDims } = useContext(MapContext);

    const [menuOpen, setMenuOpen] = useState(
        buttonsState !== BUTTONS_STATE.NONE
    );
    const [menuTop, setMenuTop] = useState(10);
    const [teaser, setTeaser] = useState(false);

    const dispatch = useDispatch();
    const unreadCount = messages.filter(m => !m.isRead && m.isOutgoing).length;

    useEffect(() => {
        if (buttonsState !== BUTTONS_STATE.NONE) {
            setMenuOpen(true);
        }
    }, [buttonsState]);

    const toggleLayers = () => {
        setMenuTop(0);
        dispatch(setMapButtonsState(BUTTONS_STATE.LAYER));
    };

    const toggleSearch = () => {
        setMenuTop(50);
        dispatch(setMapButtonsState(BUTTONS_STATE.SEARCH));
    };

    const toggleDistance = () => {
        setMenuTop(100);
        dispatch(
            setMapButtonsState(
                // buttonsState === BUTTONS_STATE.DISTANCE
                //     ? BUTTONS_STATE.NONE
                //     :
                BUTTONS_STATE.DISTANCE
            )
        );
    };
    const toggleMessages = () => {
        setMenuTop(145);
        dispatch(
            setMapButtonsState(
                // buttonsState === BUTTONS_STATE.MESSAGES
                //     ? BUTTONS_STATE.NONE
                //     :
                BUTTONS_STATE.MESSAGES
            )
        );
    };

    const toggleLine = () => {
        dispatch(toggleSeparationLine());
    };

    const toggle = () => {
        // setTeaser(false);
        // if (menuOpen) setTeaser(true);
        setMenuOpen(!menuOpen);
        dispatch(setMapButtonsState(BUTTONS_STATE.NONE));
    };
    const toggleEastWest = () => {
        dispatch(setMapButtonsState(BUTTONS_STATE.NONE));
    };

    const doTeaser = () => {
        // if (!menuOpen) {
        setTeaser(true);
        // }
    };
    const cancelTeaser = () => {
        if (!menuOpen) {
            setTeaser(false);
        }
    };

    const handleClose = () => {
        setMenuOpen(false);
        dispatch(setMapButtonsState(BUTTONS_STATE.NONE));
    };
    const Close = () => (
        <Flex alignItems="flex-end">
            <Box
                className="material-icons"
                ml="auto"
                mr={3}
                mt={3}
                color="#707070"
                style={{ cursor: 'pointer' }}
                onClick={handleClose}>
                close
            </Box>
        </Flex>
    );

    return (
        <MenuWrapper fullscreen={messagesFullscreen}>
            {isBrowser && (
                <DropMenu teaser={teaser} menuOpen={menuOpen}>
                    {unreadCount && !menuOpen ? (
                        <UnreadFlag>{unreadCount}</UnreadFlag>
                    ) : undefined}
                    <MenuItem
                        icon={MapActionsIcon}
                        caption={menuOpen || teaser ? 'Actions' : ''}
                        onMouseEnter={toggle}
                        onMouseOut={toggle}
                        teaser={teaser}
                        minWidth={teaser ? 196 : 0}
                        arrow={
                            menuOpen || teaser ? (
                                <img
                                    style={{
                                        transform: `rotate(${
                                            menuOpen ? 180 : 0
                                        }deg)`,
                                    }}
                                    src={`${imagePath}down_arrow.png`}
                                />
                            ) : undefined
                        }></MenuItem>
                    {menuOpen ? (
                        <>
                            <MenuItem
                                icon={LayerIcon}
                                caption="Layers"
                                onMouseEnter={toggleLayers}
                            />
                            <MenuItem
                                icon={SearchIcon}
                                caption="Search in Map"
                                onMouseEnter={toggleSearch}
                            />
                            <MenuItem
                                icon={DistanceIcon}
                                caption="Calculate Distance"
                                onMouseEnter={toggleDistance}
                            />
                            {id && messagesEnabled && !globalMap ? (
                                <MenuItem
                                    icon={MessagesIcon}
                                    caption="Messages"
                                    onMouseEnter={toggleMessages}>
                                    {unreadCount ? (
                                        <Box
                                            style={{ position: 'relative' }}
                                            height="15px">
                                            <UnreadFlag>
                                                {unreadCount}
                                            </UnreadFlag>
                                        </Box>
                                    ) : undefined}
                                </MenuItem>
                            ) : undefined}
                            <Box py={12} px={20} onMouseEnter={toggleEastWest}>
                                <Checkbox
                                    label="Green Line"
                                    onChange={toggleLine}
                                    value={showSeparationLine}
                                />
                            </Box>
                        </>
                    ) : undefined}
                </DropMenu>
            )}
            {isBrowser && (
                <SidePanel
                    menuTop={
                        menuOpen && buttonsState === BUTTONS_STATE.MESSAGES
                            ? null
                            : menuTop
                    }
                    fullscreen={messagesFullscreen}
                    h={
                        mapWrapperDims?.height -
                        (menuOpen && buttonsState === BUTTONS_STATE.MESSAGES
                            ? 0
                            : menuTop)
                    }>
                    <SidePanelContent
                        scroll={
                            buttonsState === BUTTONS_STATE.LAYER
                                ? 'auto'
                                : undefined
                        }>
                        {menuOpen && buttonsState === BUTTONS_STATE.LAYER ? (
                            <>
                                <SidePanelTitle title="Layers">
                                    <Close />
                                </SidePanelTitle>
                                <CheckboxTree
                                    nodes={nodes}
                                    checked={selected}
                                    onCheck={setSelected}
                                    isPropertiesSelected={isPropertiesSelected}
                                />
                            </>
                        ) : undefined}
                        {menuOpen && buttonsState === BUTTONS_STATE.SEARCH ? (
                            <>
                                <SidePanelTitle title="Search">
                                    <Close />
                                </SidePanelTitle>
                                <Box p={30} pt={3}>
                                    <PlacesSearch withIcon />
                                </Box>
                            </>
                        ) : undefined}

                        {menuOpen &&
                        !noDistance &&
                        !formControl &&
                        buttonsState === BUTTONS_STATE.DISTANCE ? (
                            <DistanceCalculator />
                        ) : undefined}

                        {menuOpen && buttonsState === BUTTONS_STATE.MESSAGES ? (
                            <MessagesViewOnMap
                                height={
                                    mapWrapperDims?.height /* - menuTop*/ - 30
                                }
                            />
                        ) : undefined}
                    </SidePanelContent>
                </SidePanel>
            )}
            <MobileView>
                {menuOpen && buttonsState === BUTTONS_STATE.LAYER ? (
                    <MobilePopup>
                        <MobilePopupHeader
                            title="Layers"
                            onClose={handleClose}
                        />
                        <CheckboxTree
                            nodes={nodes}
                            checked={selected}
                            onCheck={setSelected}
                            isPropertiesSelected={isPropertiesSelected}
                        />
                    </MobilePopup>
                ) : undefined}
            </MobileView>
        </MenuWrapper>
    );
};
