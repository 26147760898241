import {
    ROOT_URL as url,
    APPEND_FILTER,
    REMOVE_FILTER,
    SHOW_ALL_FILTER,
    FETCHING_FILTER_OPTIONS,
    FILTER_OPTIONS_FETCHED,
    FILTER,
    TOGGLE_LISTING_WELCOME_POPUP,
} from '../constants';
import filter from 'lodash/filter';

/**
 * Show all (remove filters)
 *
 */
export function showAll() {
    return {
        type: SHOW_ALL_FILTER,
    };
}

/**
 * Append the current filter
 *
 * @param  {filter} filter filter object
 * @param  {option} option option to add or remove
 */
export function appendFilter(filter, option) {
    return {
        type: APPEND_FILTER,
        filter,
        option,
    };
}

/**
 * Fetch the filter options from the server
 *
 * @param  {string} type
 */
export function removeFilter(filterId, value) {
    return {
        type: REMOVE_FILTER,
        filterId,
        value,
    };
}
/**
 * Fetch the filter options from the server
 *
 * @param  {string} type
 */
export function getFilterOptionsFromServer(filter, type) {
    return {
        type: FETCHING_FILTER_OPTIONS,
        filter: filter,
        objectType: type,
    };
}

/**
 * Return the list of options from fetched filter
 *
 */
export function filterOptionsFetched(options, filter, type) {
    if (options && options.length > 0) filter.options = options;
    return {
        type: FILTER_OPTIONS_FETCHED,
        // options: options,
        objectType: type,
        filter: filter,
    };
}

/**
 * Do filter
 *
 */
export function doFilter() {
    return {
        type: FILTER,
    };
}

/**
 * Show/hide the Listing welcome popup
 *
 */
export function toggleLisingWelcomePopup() {
    return {
        type: TOGGLE_LISTING_WELCOME_POPUP,
    };
}
