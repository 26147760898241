import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import ContactForm from './ContactForm';
import styled from 'styled-components';
import { Box } from 'reflexbox';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { useLocation } from 'react-router';
import { PROPERTY_PAGE_REGEX } from 'components/header/index';

const Trigger = styled(Box)`
    padding: 3px 20px;
    color: ${({ light, theme }) => (light ? '#FFF' : theme.grey)};
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    border: 1px solid
        ${({ light, bordered }) =>
            bordered ? (light ? '#FFF' : '#999') : 'transparent'};
    border-radius: 50px;

    &:hover {
        background: ${({ withoutHover, theme }) =>
            withoutHover ? '#FFF' : theme.gold};
        color: ${({ withoutHover }) => (withoutHover ? 'inherit' : '#FFF')};
        border-color: transparent;
    }
`;

const ContactModal = ({ label, open, trigger, onOpen, onClose, ...props }) => {
    const [show, setShow] = useState(open);

    const handleClose = () => {
        setShow(false);
        if (onClose) onClose();
    };
    const handleShow = () => {
        setShow(true);
        if (onOpen) onOpen();
    };

    useEffect(() => {
        setShow(open);
    }, [open]);

    const { pathname } = useLocation();
    const isPropertyPage = pathname.match(PROPERTY_PAGE_REGEX);

    return (
        <>
            {trigger ? (
                React.cloneElement(trigger, { onClick: handleShow })
            ) : (
                <Trigger
                    onClick={handleShow}
                    fontSize={['12px', '13px']}
                    {...props}>
                    {label || 'Contact us'}
                </Trigger>
            )}

            <Modal
                show={show}
                onHide={handleClose}
                scrollable
                animation={false}
                className="contact-modal"
                style={{
                    height: isMobile ? 'auto' : 'calc(100svh - 50px)',
                    bottom: isMobile ? -20 : 0,
                    top: 'auto',
                }}>
                <Modal.Header
                    style={{
                        borderBottom: '2px solid #CEC4AA',
                        background: '#FCFBF9',
                        justifyContent: 'center',
                    }}>
                    <BrowserView>
                        <Box
                            className="material-icons"
                            style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                right: 15,
                                top: 15,
                            }}
                            onClick={handleClose}>
                            close
                        </Box>
                        <Box p="5px 0px" width={1} textAlign="center">
                            <Box
                                fontSize="18px"
                                lineHeight="25px"
                                fontWeight="bold"
                                color="#808080"
                                mb={14}
                                mt={25}>
                                Interested in a property that suites your needs?
                            </Box>
                            <Box
                                fontSize="14px"
                                lineHeight="22px"
                                fontWeight="500"
                                color="#9F9F9F">
                                Contact us today and let us be at your disposal
                                <br />
                                Tel +972 (0)2 656 4322
                            </Box>
                        </Box>
                    </BrowserView>
                    <MobileView>
                        <Box fontSize="15px" fontWeight="600" pl={3} py={2}>
                            Contact us{' '}
                            {isPropertyPage ? ' about this property' : ''}
                        </Box>
                        <Box
                            className="material-icons"
                            style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                right: 15,
                                top: 15,
                            }}
                            onClick={handleClose}>
                            close
                        </Box>
                    </MobileView>
                </Modal.Header>
                <Modal.Body>
                    <Box mt={3}>
                        <ContactForm handleClose={handleClose} />
                        <MobileView>
                            <Box height="70px" />
                        </MobileView>
                    </Box>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default ContactModal;
