import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Box, Flex } from 'reflexbox';

const Popup = styled.div`
    position: absolute;
    background: ${({ open }) => (open ? '#FFF' : 'transparent')};
    padding: 10px 24px;
    border-radius: 10px;

    ${({ open, transparent }) =>
        open &&
        !transparent &&
        css`
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #e2e2e2;
        `}

    a,
    a:visited {
        color: ${({ theme }) => theme.grey};
        display: block;
        line-height: 18px;
        margin: 10px 0px;
        text-decoration: none;
        font-weight: 500;
        font-size: 13px;
        padding: 0px;
    }
    a:hover {
        color: ${({ theme }) => theme.gold} !important;
    }
`;

const Label = styled(Flex)`
    color: ${({ transparent, open, theme }) =>
        open ? theme.primary : transparent ? '#FFF' : theme.grey};
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
    line-height: 18px;
    align-items: center;
`;

function HeaderMenu({ label, transparent, children, ...rest }) {
    const ref = useRef(null);

    const [open, setOpen] = useState();

    const toggleMenu = () => {
        setOpen(!open);
    };

    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return (
        <Box ref={ref} style={{ position: 'relative' }} width={140} {...rest}>
            <Popup open={open} transparent={transparent}>
                <Label
                    onClick={toggleMenu}
                    open={open}
                    alignItems="center"
                    transparent={transparent}>
                    <Box pb={2} style={{ whiteSpace: 'nowrap' }}>
                        {label}
                    </Box>
                    <Box
                        className="material-icons-outlined"
                        pl={2}
                        pb="6px"
                        fontSize="15px">
                        keyboard_arrow_down
                    </Box>
                </Label>
                {open
                    ? React.Children.map(children, child =>
                          React.cloneElement(child, {
                              onClick: () => {
                                  if (child.props.onClick)
                                      child.props.onClick();
                                  toggleMenu();
                              },
                          })
                      )
                    : undefined}
            </Popup>
        </Box>
    );
}

export default HeaderMenu;
