import React, { useState, useEffect } from 'react';
import lint from 'durable-json-lint';

export const DRAWING_TYPES = {
    POLYGON: 'polygon',
    POLYLINE: 'polyline',
    CIRCLE: 'circle',
    RECTANGLE: 'rectangle',
};
export const GoogleMapDrawing = ({ map, mapsApi, drawing }) => {
    const { shapeType, shape } = drawing;
    const [draw, setDraw] = useState();
    // const [accented, setAccented] = useState();

    useEffect(() => {
        return () => {
            if (draw) {
                draw.setMap();
            }
        };
    }, []);

    useEffect(() => {
        if (draw) draw.setMap();
        if (map && mapsApi) {
            const parsedJson = JSON.parse(lint(shape).json);

            let drawObj;
            switch (shapeType) {
                case DRAWING_TYPES.POLYGON:
                    drawObj = new mapsApi.Polygon(parsedJson);
                    // setAccented(
                    //     new mapsApi.Polygon({
                    //         ...parsedJson,
                    //         fillOpacity: parsedJson?.fillOpacity + 0.2,
                    //     })
                    // );
                    break;
                case DRAWING_TYPES.POLYLINE:
                    drawObj = new mapsApi.Polyline(parsedJson);
                    break;
                case DRAWING_TYPES.CIRCLE:
                    drawObj = new mapsApi.Circle(parsedJson);
                    break;
                case DRAWING_TYPES.RECTANGLE:
                    drawObj = new mapsApi.Rectangle(parsedJson);
                    break;
                default:
                    break;
            }
            setDraw(drawObj);
        }
        return () => {
            if (draw) draw.setMap();
        };
    }, [map, mapsApi, drawing]);

    useEffect(() => {
        if (draw) {
            draw.setMap(map);
            // if (accented) {
            //     mapsApi.event.addListener(draw, 'mouseover', () => {
            //         accented.setMap(map);
            //     });
            //     mapsApi.event.addListener(draw, 'mouseout', () => {
            //         accented.setMap();
            //     });
            // }
        }
        return () => {
            if (draw) {
                draw.setMap();
            }
        };
    }, [draw]);

    return null;
};

GoogleMapDrawing.defaultProps = {
    drawing: {
        shape: '',
        shapeType: 'polygon',
    },
};
