import React from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { theme } from 'index';

const StyledHolder = styled.div`
    height: 26px;
    width: 47px;
    background: ${({ checked }) => (checked ? '#F7F4EC' : '#F2F2F2')};
    position: relative;
    transition: all 0.2s ease-in-out;
    border-radius: 50px;
`;
const StyledKnob = styled.div`
    height: 20px;
    width: 20px;
    margin-top: 3px;
    background: ${({ checked }) => (checked ? theme.gold : '#A5A5A5')};
    position: absolute;
    left: ${({ checked }) => (checked ? 3 : 24)}px;
    transition: all 0.2s ease-in-out;
    border-radius: 50px;
`;
const StyledLabel = styled.div`
    font-size: 12px;
    font-weight: 600;
    color: #9b9b9b;
    margin-left: 10px;
    user-select: none;
`;

const Switch = ({ label, value, onChange }) => {
    return (
        <Flex
            mr={3}
            sx={{ cursor: 'pointer' }}
            onClick={() => onChange(!value)}
            alignItems="center">
            <StyledHolder checked={value}>
                <StyledKnob checked={value} />
            </StyledHolder>
            <StyledLabel>{label}</StyledLabel>
        </Flex>
    );
};

export default Switch;
