import React from 'react';
import { Box, Flex } from 'reflexbox';
import styled from 'styled-components';

const StyledContent = styled(Box)`
    & span {
        color: inherit !important;
    }
`;

const Attrib = ({ caption, content, ...rest }) => (
    <Flex bg="#f7f7f7" px="10px" py="5px" {...rest}>
        <Box fontWeight="700" flexShrink={0} minWidth={76} mr={1}>
            {caption}:
        </Box>
        <StyledContent
            dangerouslySetInnerHTML={{ __html: content }}></StyledContent>
    </Flex>
);

const PropertyAttribComments = props => {
    const {
        availabilityStatus,
        availabilityComment,
        furnitureComment,
        priceComment,
        furnitureStatus,
        note,
        ...rest
    } = props;

    return (
        <>
            <Box mb={4} style={{ borderRadius: '8px', overflow: 'hidden' }}>
                {availabilityComment ? (
                    <Attrib
                        caption={'Availability'} //STATUSES[availabilityStatus]}
                        content={availabilityComment}
                        style={{ borderBottom: '3px solid #FFF' }}
                    />
                ) : undefined}
                {furnitureComment ? (
                    <Attrib
                        caption={'Furniture'} //furniture(furnitureStatus)}
                        content={furnitureComment}
                        style={{ borderBottom: '3px solid #FFF' }}
                    />
                ) : undefined}
                {priceComment ? (
                    <Attrib
                        caption="Price"
                        content={priceComment}
                        style={{
                            borderBottom: note ? '3px solid #FFF' : 'none',
                        }}
                    />
                ) : undefined}
                {note ? <Attrib caption="Note" content={note} /> : undefined}
            </Box>
        </>
    );
};

export default PropertyAttribComments;
