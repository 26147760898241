import { all, call, select, takeLatest, put } from 'redux-saga/effects';
import Axios from 'axios';
import {
    ROOT_URL,
    FETCH_CUSTOM_MAPS,
    GET_CUSTOM_MAP,
    DELETE_CUSTOM_MAP,
    MARK_MESSAGES_AS_READ,
    MESSAGES_REPLY,
    REMOVE_PROPERTY_FROM_ALL_MAPS,
    UPDATE_RELEVANCE,
    SET_CUSTOMER_COMMENT,
    CUSTOM_MAP_FILTER_REQUEST,
    RESET_CUSTOM_MAP_FILTERS,
    RESET_CUSTOM_MAP_FILTERS_APPLIED,
} from 'constants';
import {
    setBusy,
    setIdle,
    fetchCustomMapsSuccess,
    fetchCustomMapsFailure,
    getCustomMapSuccess,
    deleteCustomMapSuccess,
    deleteCustomMapFailure,
    applyCustomMapFilter,
    filterCustomMapProperties,
} from 'actions';
import { push } from 'connected-react-router';
import { sortBy } from 'lodash';
import { RELEVANCE } from 'components/customMap/constants';
import { setCustomMapFilter } from '../../actions/customMaps';

/***********************************
 *
 * Get CustomMaps
 *
 **********************************/
function* getCustomMaps() {
    yield put(setBusy());
    try {
        const response = yield Axios.get(`${ROOT_URL}/customMaps`);

        yield put(
            fetchCustomMapsSuccess(
                sortBy(
                    response.data.sort((a, b) =>
                        a.created < b.created ? 1 : -1
                    )
                )
            )
        );
    } catch (e) {
        console.log(e);
        yield put(fetchCustomMapsFailure(e));
    }
    yield put(setIdle());
}

/***********************************
 *
 * Get customMap
 *
 **********************************/
function* getCustomMap({ id, isDuplicate, preventReload }) {
    if (!preventReload) yield put(setBusy());

    try {
        const response = yield Axios.get(`${ROOT_URL}/customMaps/${id}`);
        const customMap = isDuplicate
            ? {
                  properties: response.data.properties,
                  propertiesMeta: response.data.propertiesMeta,
                  neighborhoods: response.data.neighborhoods,
                  places: response.data.places,
              }
            : response.data;

        if (customMap?.propertiesMeta?.filter(m => m.order === 0).length > 1) {
            //meta not ordered

            customMap.propertiesMeta = customMap.propertiesMeta.map(
                (m, index) => ({ ...m, order: index })
            );
        }

        customMap.properties = sortBy(
            customMap?.properties?.map((property, index) => {
                if (property) {
                    const meta =
                        customMap?.propertiesMeta?.find(
                            p => p.propertyId === property.id
                        ) || {};

                    return { ...property, ...meta };
                }
            }),
            ['order']
        );

        yield put(getCustomMapSuccess(customMap));
        yield call(filterProperties);
    } catch (e) {
        console.log(e);
        // yield put(push('/'));
    }
    if (!preventReload) yield put(setIdle());
}

/***********************************
 *
 * Delete customMap
 *
 **********************************/
function* deleteCustomMap({ id }) {
    try {
        const response = yield Axios.delete(`${ROOT_URL}/customMaps/${id}`);

        yield put(deleteCustomMapSuccess(id));
        yield put(push('/admin/maps'));
    } catch (e) {
        console.log(e);
        yield put(deleteCustomMapFailure(e));
    }
}

/***********************************
 *
 * Mark messages as read
 *
 **********************************/
function* markAsRead({ preventReload }) {
    const { id } = yield select(state => state.customMap.object);

    try {
        yield Axios.post(`${ROOT_URL}/messageRead`, { mapId: id });

        if (preventReload)
            yield call(getCustomMap, { id, preventReload: preventReload });
    } catch (e) {
        console.log(e);
    }
}

/***********************************
 *
 * Reply to message
 *
 **********************************/
function* reply({ message, propertyId }) {
    const { id } = yield select(state => state.customMap.object);

    try {
        yield Axios.post(`${ROOT_URL}/customMapReply`, {
            mapId: id,
            message,
            propertyId,
        });
        yield call(getCustomMap, { id });
    } catch (e) {
        console.log(e);
    }
}

/***********************************
 *
 * Remove property from all custom maps
 *
 **********************************/
function* removePropFromAllMaps({ propertyId }) {
    try {
        yield Axios.post(`${ROOT_URL}/removePropertyFromAllCustomMaps`, {
            propertyId,
        });
    } catch (e) {
        console.log(e);
    }
}

/***********************************
 *
 * Update property relevance
 *
 **********************************/
function* handleUpdateRelevance({ relevance, propertyId, showRelevance }) {
    const {
        customMap: { object: customMap },
        properties,
    } = yield select(state => state);
    const { propertiesMeta } = customMap;
    const property = properties.find(p => p.id === propertyId);

    const meta = propertiesMeta?.find(m => m.propertyId === propertyId);
    meta.customerRelevance = relevance;

    if (showRelevance !== undefined) {
        customMap.hideNotInterested = showRelevance;
    }

    if (relevance === 'notInteresting') {
        meta.visible = !customMap.hideNotInterested;
    }

    try {
        yield Axios.post(`${ROOT_URL}/customMaps/save`, customMap);
        yield call(getCustomMap, { id: customMap.id });

        const relev = RELEVANCE.find(r => r.id === relevance)?.title;
        if (relev) {
            Axios.post(`${ROOT_URL}/customMapPropertyTag`, {
                id: customMap.id,
                propertyId: property.id,
                tag: relev,
            });
        }
    } catch (e) {
        console.log(e);
    }
}

/***********************************
 *
 * Update property category
 *
 **********************************/
const OPTIONS = [
    'Highly interesting',
    'Potential',
    'Worth visiting',
    'Not interesting',
];
// THERE IS A SIMILAR LIST IN InterestInPropertyComponent AND SERVER

function* handleUpdateCategories({ category, propertyId, showRelevance }) {
    const {
        customMap: { object: customMap },
        properties,
    } = yield select(state => state);

    const { propertiesMeta } = customMap;

    const meta = propertiesMeta?.find(m => m.propertyId === propertyId);
    const cleaned =
        meta.categories?.filter(item => !OPTIONS.includes(item)) || [];
    meta.categories = [...cleaned, category];

    customMap.propertiesCategories = propertiesMeta.flatMap(
        item => item.categories || []
    );

    if (showRelevance !== undefined) {
        customMap.hideNotInterested = showRelevance;
    }

    if (meta.categories.indexOf('Not interesting') >= 0) {
        meta.visible = !customMap.hideNotInterested;
    }

    customMap.propertiesMeta = propertiesMeta.map(p =>
        p.propertyId === propertyId ? meta : p
    );

    try {
        // yield Axios.post(`${ROOT_URL}/customMaps/save`, customMap);

        // const relev = RELEVANCE.find(r => r.id === relevance)?.title;
        if (category) {
            const res = Axios.post(`${ROOT_URL}/customMapPropertyTag`, {
                id: customMap.id,
                propertyId,
                tag: category,
            });
            // yield call(getCustomMap, { id: customMap.id, preventReload: true });
            // if (res.status === 200)
            // yield put(getCustomMapSuccess(JSON.parse(res.data)));
            debugger;
            yield put(getCustomMapSuccess(customMap));
        }
    } catch (e) {
        console.log(e);
    }
}

/***********************************
 *
 * Set customer comment about a property
 *
 **********************************/
function* handleCustomerComment({ comment, propertyId }) {
    const customMap = yield select(state => state.customMap.object);
    const { propertiesMeta } = customMap;
    const meta = propertiesMeta?.find(m => m.propertyId === propertyId);

    if (meta) {
        meta.customerComment = comment;
    } else {
        propertiesMeta.push({ propertyId, customerComment: comment });
    }

    try {
        // yield Axios.post(`${ROOT_URL}/customMaps/save`, customMap);
        yield Axios.post(`${ROOT_URL}/customMapPropertyComment`, {
            mapId: customMap.id,
            propertyId,
            comment,
        });
        yield call(getCustomMap, { id: customMap.id });
    } catch (e) {
        console.log(e);
    }
}

/***********************************
 *
 * Filter custom map properties
 *
 **********************************/
function* handleCustomMapFilterRequest({ name, value }) {
    try {
        yield put(applyCustomMapFilter(name, value));
        yield call(filterProperties);
    } catch (e) {
        console.error(e);
    }
}

/***********************************
 *
 * Reset custom map filters
 *
 **********************************/
function* resetFilters() {
    try {
        yield put({ type: RESET_CUSTOM_MAP_FILTERS_APPLIED });
        yield call(filterProperties);
    } catch (e) {
        console.error(e);
    }
}

function* filterProperties() {
    const {
        object: { properties },
        filters: { neighborhood, status, recommended, relevance, visible },
    } = yield select(state => state.customMap);

    try {
        const filtered = properties?.filter(p => {
            const isNeighborhood =
                !neighborhood.length ||
                neighborhood.map(v => v.id).includes(p.neighborhood?.id);

            const isStatus =
                !status.length ||
                status.map(v => v.id).some(s => p.statuses?.indexOf(s) >= 0);

            const isRecommended =
                (recommended && p.recommended) || recommended === false;

            const vSum = visible.map(v => v.id).reduce((a, c) => a + c);

            const isVisible =
                vSum === 0 ||
                vSum === 3 ||
                (vSum === 1 && p.visible) ||
                (vSum === 2 && !p.visible);

            const isRelevant =
                !relevance.length ||
                relevance.map(r => r.id).includes(p.customerRelevance);

            return (
                isNeighborhood &&
                isStatus &&
                isRecommended &&
                isVisible &&
                isRelevant
            );
        });
        yield put(filterCustomMapProperties(filtered));
    } catch (e) {
        console.error(e);
    }
}

export default function* () {
    yield all([
        takeLatest(FETCH_CUSTOM_MAPS, getCustomMaps),
        takeLatest(GET_CUSTOM_MAP, getCustomMap),
        takeLatest(DELETE_CUSTOM_MAP, deleteCustomMap),
        takeLatest(MARK_MESSAGES_AS_READ, markAsRead),
        takeLatest(MESSAGES_REPLY, reply),
        takeLatest(REMOVE_PROPERTY_FROM_ALL_MAPS, removePropFromAllMaps),
        takeLatest(UPDATE_RELEVANCE, handleUpdateCategories),
        takeLatest(SET_CUSTOMER_COMMENT, handleCustomerComment),
        takeLatest(CUSTOM_MAP_FILTER_REQUEST, handleCustomMapFilterRequest),
        takeLatest(RESET_CUSTOM_MAP_FILTERS, resetFilters),
    ]);
}
