import { theme } from 'index';
import React from 'react';
import { Flex, Box } from 'reflexbox';

export const MobilePopupHeader = ({ title, onClose }) => {
    return (
        <Flex
            px={3}
            height={75}
            alignItems="center"
            style={{ borderBottom: `2px solid ${theme.gold}` }}>
            <Box
                className="material-icons"
                color={theme.gold}
                onClick={onClose}
                mr={2}
                fontSize="30px"
                minWidth="auto"
                ml="-6px">
                chevron_left
            </Box>
            <Box
                color="$606060"
                fontSize="15px"
                fontWeight="700"
                lineHeight="18px"
                flexGrow={1}>
                <Box>{title}</Box>
            </Box>
        </Flex>
    );
};
