import React from 'react';
import { Flex, Box } from 'reflexbox';
import { trimEnd } from 'lodash';
import RoundedButton from 'components/common/RoundedButton';
import { PropertyTitle, TooltipClose } from '../StyledComponents';
import { selectDistancePoint, setMapButtonsState } from 'actions';
import { useDispatch } from 'react-redux';
import { BUTTONS_STATE } from 'constants';
import DistanceCalculator from './DistanceCalculator';
import Styled from 'styled-components';
import {
    BrowserView,
    isBrowser,
    isMobile,
    MobileView,
} from 'react-device-detect';
import { theme } from 'index';
import { setInfoWindow } from 'actions/map';

const CommentContent = props => {
    const { popupTitle, buttonTitle, buttonUrl, content, ...rest } = props;

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setInfoWindow());
    };

    return (
        <>
            <BrowserView>
                <Box>
                    <Flex
                        bg="#0B99FF"
                        color="#FFF"
                        p={'10px 24px'}
                        alignItems="center"
                        sx={{ borderRadius: '6px 6px 0px 0px' }}
                        fontWeight="600">
                        <Box className="material-icons">messages</Box>
                        <Box flexGrow={1} fontSize="16px">
                            {popupTitle}
                        </Box>
                        <Box
                            className="material-icons"
                            onClick={handleClose}
                            sx={{ cursor: 'pointer' }}>
                            close
                        </Box>
                    </Flex>
                    <Box dangerouslySetInnerHTML={{ __html: content }} p={3} />
                    {buttonUrl && buttonTitle ? (
                        <Flex p={3} justifyContent="center">
                            <RoundedButton
                                small
                                href={buttonUrl}
                                target="_blank">
                                {buttonTitle}
                            </RoundedButton>
                        </Flex>
                    ) : undefined}
                </Box>
            </BrowserView>
            <MobileView>
                <Box height="100%" style={{ position: 'relative' }}>
                    <Flex height={1}>
                        <Box style={{ position: 'relative' }} flexGrow={1}>
                            <Flex
                                px={3}
                                height={75}
                                alignItems="center"
                                style={{
                                    borderBottom: `2px solid ${theme.gold}`,
                                }}>
                                <Box
                                    className="material-icons"
                                    color={theme.gold}
                                    onClick={handleClose}
                                    mr={2}
                                    fontSize="30px"
                                    minWidth="auto"
                                    ml="-6px">
                                    chevron_left
                                </Box>
                                <Box
                                    color="$606060"
                                    fontSize="15px"
                                    fontWeight="700"
                                    lineHeight="18px"
                                    flexGrow={1}>
                                    <Box>{popupTitle}</Box>
                                </Box>
                            </Flex>
                            <Box
                                dangerouslySetInnerHTML={{ __html: content }}
                                p={3}
                            />
                            {buttonUrl && buttonTitle ? (
                                <Flex p={3} justifyContent="center">
                                    <RoundedButton small to={buttonUrl}>
                                        {buttonTitle}
                                    </RoundedButton>
                                </Flex>
                            ) : undefined}
                        </Box>
                    </Flex>
                </Box>
            </MobileView>
        </>
    );
};

export default CommentContent;
