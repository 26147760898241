import {
    setMapButtonsState,
    setLiveLocation,
    toggleLiveLocation,
    toggleSeparationLine,
} from 'actions/map';
import Checkbox from 'components/common/Checkbox';
import { BUTTONS_STATE } from 'constants';
import Switch from 'elements/Switch';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'reflexbox';
import ActionMenu from '../../elements/ActionMenu';
import { ReactComponent as ActionsIcons } from './icons/map_actions_icon.svg';
import { ReactComponent as LayersIcon } from './icons/map_layers_icon.svg';

const MapActions = ({
    markersVisibility,
    onVisibilityChange,
    nearBy,
    onNearByChange,
    mapsApi,
    mapObject,
    ...rest
}) => {
    const {
        map: { showSeparationLine },
        property,
    } = useSelector(state => state);

    const dispatch = useDispatch();

    const toggleLine = () => {
        dispatch(toggleSeparationLine());
    };

    const handleLiveLocation = () => {
        dispatch(toggleLiveLocation());
    };

    const items = [
        {
            label: 'Actions',
            items: [],
        },
    ];

    if (markersVisibility) {
        items[0].items = [
            {
                custom: (
                    <Box py={3} pl={1}>
                        <Switch
                            label={'Properties'}
                            value={markersVisibility['properties']}
                            onChange={() => {
                                onVisibilityChange({
                                    ...markersVisibility,
                                    properties:
                                        !markersVisibility['properties'],
                                });
                            }}
                        />
                    </Box>
                ),
            },
            {
                custom: (
                    <Box py={3} pl={1}>
                        <Switch
                            label={'Neighborhoods'}
                            value={markersVisibility['neighborhoods']}
                            onChange={() => {
                                onVisibilityChange({
                                    ...markersVisibility,
                                    neighborhoods:
                                        !markersVisibility['neighborhoods'],
                                });
                            }}
                        />
                    </Box>
                ),
            },
            {
                custom: (
                    <Box py={3} pl={1}>
                        <Switch
                            label={'Surroundings'}
                            value={markersVisibility['places']}
                            onChange={() => {
                                onVisibilityChange({
                                    ...markersVisibility,
                                    places: !markersVisibility['places'],
                                });
                            }}
                        />
                    </Box>
                ),
            },
            {
                custom: (
                    <Box sx={{ width: '100%', height: '1px', bg: '#E1E1E1' }} />
                ),
            },
        ];
    }

    if (nearBy) {
        items[0].items.push({
            custom: (
                <Box py={3} pl={1}>
                    <Switch
                        label={'Near by'}
                        value={nearBy === 'Nearby'}
                        onChange={() => {
                            onNearByChange(
                                nearBy === 'Nearby' ? 'Off' : 'Nearby'
                            );
                        }}
                    />
                </Box>
            ),
        });
        items[0].items.push({
            custom: (
                <Box sx={{ width: '100%', height: '1px', bg: '#E1E1E1' }} />
            ),
        });
    }

    items[0].items.push({
        custom: (
            <Box pt={3} pl={'12px'}>
                <Checkbox
                    label="Green Line"
                    onChange={toggleLine}
                    value={showSeparationLine}
                    labelStyles={{ paddingLeft: '36px' }}
                />
            </Box>
        ),
    });

    items[0].items.push({
        custom: (
            <Flex onClick={handleLiveLocation} pt={3} pb={2}>
                <Box
                    className="material-icons"
                    color="rgb(204, 204, 204)"
                    pr={3}
                    pl={'9px'}>
                    my_location
                </Box>
                <Box pl="3px">Live location</Box>
            </Flex>
        ),
    });

    items[0].items.push({
        icon: <LayersIcon />,
        label: 'Layers',
        onClick: () => {
            dispatch(setMapButtonsState(BUTTONS_STATE.LAYER));
        },
    });

    return (
        <Box
            sx={{ position: 'absolute', bottom: 20, right: 60, zIndex: 2 }}
            id="mobile-actions">
            <ActionMenu buttonIcon={<ActionsIcons />} items={items} />
        </Box>
    );
};

export default MapActions;
