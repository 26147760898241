import { PropertyFilters } from 'components/search/filter/PropertyFilters';
import { ItemsFilters } from 'components/search/filter/ItemsFilters';
import { BuildingFilters } from 'components/search/filter/BuildingFilters';
import {
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_SUCCESS,
    FETCH_PROPERTIES,
    FETCH_PROPERTIES_FAILURE,
    FETCH_PROPERTIES_SUCCESS,
    FETCH_RELATED_PROPERTIES,
    REMOVE_VISIBILITY_FILTER,
    SET_VISIBILITY_FILTER,
    SORT_PROPERTIES_SUCCESS,
    FILTER_OPTIONS_FETCHED,
    PAGINATE,
    SWITCH_VIEW,
    TOGGLE_LISTING_WELCOME_POPUP,
} from 'constants';

const initState = {
    filtered: [],
    sortBy: 'bedrooms',
    sortDirection: 'asc',
    listView: 'map',
    isFetching: true,
};

export const visibilityFilter = (state = initState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case FETCH_PROPERTIES:
        case FETCH_ITEMS:
            newState.isFetching = true;
            return newState;

        case FETCH_PROPERTIES_SUCCESS:
            newState.isFetching = false;
            newState.filtered = action.payload;
            return newState;
        case FETCH_PROPERTIES_FAILURE:
        case FETCH_ITEMS_SUCCESS:
        case FETCH_ITEMS_FAILURE:
            newState.isFetching = false;
            return newState;

        case FETCH_RELATED_PROPERTIES:
            newState.filtered = action.payload;
            return newState;
        case SET_VISIBILITY_FILTER:
            newState.filtered = action.filtered;
            return newState;

        case REMOVE_VISIBILITY_FILTER:
            newState.filtered = action.filtered;
            return newState;

        case SORT_PROPERTIES_SUCCESS:
            newState.filtered = action.filtered;
            newState.sortBy = action.sortBy;
            newState.sortDirection = action.sortDirection;
            return newState;

        case FILTER_OPTIONS_FETCHED:
            let opts = action.filter.options || [];

            action.filter.options = opts;
            let filter = {
                filter: action.filter,
                objectType: action.objectType,
            };

            if (!newState.filter_options) {
                newState.filter_options = [];
            }
            const foundIndex = newState.filter_options.findIndex(
                f => f.filter.id === action.filter.id
            );

            if (foundIndex > -1) {
                newState.filter_options[foundIndex] = filter;
            } else {
                newState.filter_options.push(filter);
            }

            return newState;

        case PAGINATE:
            newState.page = action.page;

            return newState;
        case SWITCH_VIEW:
            newState.listView = action.view;
            return newState;
        case TOGGLE_LISTING_WELCOME_POPUP:
            newState.showListingWelcomePopup = !state.showListingWelcomePopup;
            return newState;
        default:
            return state;
    }
};

export default visibilityFilter;
