import React from 'react';
import styled, { css } from 'styled-components';
import { Flex, Box } from 'reflexbox';

export const Wrapper = styled(Flex)`
    ${({ fullScreen }) =>
        fullScreen
            ? css`
                  position: fixed;
                  top: 0px;
                  bottom: 0px;
                  left: 0px;
                  right: 0px;
                  z-index: 10;
              `
            : css`
                  position: relative;
                  overflow: hidden;
              `}
`;

export const MapLoading = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background: #f7f4ec;
    align-items: center;
    justify-content: center;
`;

export const SidePanelTitle = ({ title, children }) => (
    <Flex alignItems="flex-start" justifyContent="space-between">
        <Box
            color="#6e6e6e"
            fontSize="20px"
            lineHeight="27px"
            fontWeight={500}
            pl={30}
            mt={15}
            mb={20}
            style={{ marginBottom: 20 }}>
            {title}
        </Box>
        {children}
    </Flex>
);

export const MenuWrapper = styled(Flex)`
    position: absolute;
    top: 10px;
    left: 10px;
    bottom: 20px;
    z-index: ${fullscreen => (fullscreen ? 9 : 1)};
    pointer-events: none;
`;
export const DropMenu = styled(Flex)`
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: ${({ teaser, menuOpen }) =>
        !menuOpen && !teaser ? 50 : 6}px;
    flex-direction: column;
    align-self: flex-start;
    cursor: pointer;
    overflow: hidden;
    pointer-events: all;
    label {
        font-weight: bold;
        padding-left: 13px;
    }
`;

export const MenuItemWrapper = styled(Flex)`
    position: relative;
    align-items: center;
    padding: 8px;
    // transition: all 0.25s ease-in-out;
    border-bottom: 1px solid #ebebeb;
`;

export const SidePanel = styled(Box)`
    position: ${({ fullscreen }) => (fullscreen ? 'fixed' : 'relative')};
    left: 5px;
    top: ${({ menuTop, fullscreen }) => (fullscreen ? 80 : menuTop)}px;
    bottom: ${({ fullscreen }) => (fullscreen ? '20px' : 'auto')};
    width: ${({ fullscreen }) => (fullscreen ? 'calc(100% - 15px)' : 'auto')};
    max-height: ${({ fullscreen, h }) => (fullscreen ? '100%' : h - 30)}px;
    z-index: ${({ fullscreen }) => (fullscreen ? 9 : 2)};
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 3px 12px #00000040;
    border-radius: 6px;
    background: #fff;
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    pointer-events: all;
`;
export const SidePanelContent = styled(Box)`
    height: 100%;
    overflow: ${({ scroll }) => scroll};
`;

export const Trigger = styled(Flex)`
    position: absolute;
    right: -35px;
    top: 75px;
    width: 35px;
    height: 42px;
    border-radius: 0px 50px 50px 0px;
    box-shadow: 9px 3px 10px #00000020;
    background: white;
    z-index: 1;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.gold};
    cursor: pointer;
`;

export const FullScreenButton = styled(Box)`
    position: absolute;
    top: 10px;
    right: 10px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    background: #ffffff;
    z-index: 1;
    padding: 3px;
    font-size: 33px;
    cursor: pointer;
`;

// Checkboxtree elements
export const Expandeable = styled.div`
    max-height: ${({ open }) => (open ? '1000px' : '45px')};
    transition: all 0.2s ease-in-out;
    background: ${({ open, dark }) => (open && dark ? '#F7F7F7' : '#FFF')};
    overflow: hidden;
`;
export const ExpandeableAngle = styled.i`
    transform: rotate(${({ open }) => (open ? 90 : 0)}deg);
    transform-origin: center;
    padding: 5px;
    margin-left: 10px;
    cursor: pointer;
`;
export const ExpandeableContent = styled.div`
    padding-left: 20px;
`;
export const ExpandeableLabel = styled.div`
    cursor: pointer;
`;

export const CheckItem = styled(Flex)`
    margin: 0px 30px;
    border-bottom: 1px solid #ebebeb;
    align-items: center;
    padding: 15px 30px 10px;
    g {
        fill: ${({ theme }) => theme.gold};
    }
`;

export const Pointer = styled.a`
    cursor: pointer;
    padding-left: 10px;
    &:hover {
        text-decoration: none;
    }
`;

export const UnreadFlag = styled.div`
    background: red;
    position: absolute;
    right: 5px;
    top: 5px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 10px;
    width: 15px;
    height: 15px;
    border-radius: 25px;
    text-align: center;
    pointer-events: none;
    z-index: 2;
`;
