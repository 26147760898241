import React, { useState } from 'react';
import axios from 'axios';
import { Box, Flex } from 'reflexbox';
import { StyledButton, StyledCancel, ContactFormInput } from './styled';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormikHOC } from 'elements/FormikHOC';
import { isBrowser, isMobile } from 'react-device-detect';

const phoneRegex =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const Schema = Yup.object().shape({
    first: Yup.string()
        .min(1, 'First name too short')
        .max(30, 'First name too long!')
        .required('First name is required'),
    last: Yup.string()
        .min(1, 'Last name too short')
        .max(30, 'Last name too long!')
        .required('Last name is required'),
    email: Yup.string()
        .email('This email seems invalid')
        .required('Email is required'),
    phone: Yup.string().matches(phoneRegex, 'Phone number is not valid'),
    subject: Yup.string().required('Please write subject'),
    message: Yup.string().required('Please add your message here'),
});

const initialValues = {
    first: '',
    last: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
    propertyUrl: window.location.href,
};

const ContactForm = ({ footer, handleClose }) => {
    const [done, setDone] = useState(false);

    const handleSubmit = values => {
        axios.post('/api/zoho', values).then(response => {
            if (response.status === 200) {
                setDone(true);
            } else {
                alert(
                    'Sorry, something went wrong and we were not able to receive your information'
                );
            }
        });
    };
    return (
        <Box px={30}>
            {done ? (
                <>
                    <Box as="h4" textAlign="center" pt={4} pb={3}>
                        Thank you for your interest in working with us
                    </Box>
                    <Box as={'p'} pb={4} textAlign="center">
                        We'll get in contact soon.
                    </Box>
                </>
            ) : (
                <Formik
                    initialValues={initialValues}
                    validationSchema={Schema}
                    onSubmit={handleSubmit}>
                    {({ errors, touched }) => (
                        <Form>
                            <Flex
                                maxWidth={900}
                                mx="auto"
                                flexDirection={footer ? 'row' : 'column'}>
                                <Box
                                    pr={footer ? 4 : 0}
                                    width={footer ? 1 / 2 : 1}>
                                    <Field
                                        uiComponent={ContactFormInput}
                                        component={FormikHOC}
                                        footer={footer}
                                        name="first"
                                        placeholder="First Name*"
                                        required
                                    />
                                    <Field
                                        uiComponent={ContactFormInput}
                                        component={FormikHOC}
                                        footer={footer}
                                        name="last"
                                        placeholder="Last Name*"
                                        required
                                    />
                                    <Field
                                        uiComponent={ContactFormInput}
                                        component={FormikHOC}
                                        footer={footer}
                                        name="email"
                                        placeholder="Email Address*"
                                        required
                                    />
                                    <Field
                                        uiComponent={ContactFormInput}
                                        component={FormikHOC}
                                        footer={footer}
                                        name="phone"
                                        placeholder="Mobile Number*"
                                    />
                                </Box>
                                <Box
                                    textAlign="initial"
                                    width={footer ? 1 / 2 : 1}>
                                    <Field
                                        uiComponent={ContactFormInput}
                                        component={FormikHOC}
                                        footer={footer}
                                        name="subject"
                                        placeholder="Subject*"
                                    />
                                    <Field
                                        uiComponent={ContactFormInput}
                                        component={FormikHOC}
                                        footer={footer}
                                        comp="textarea"
                                        radius={20}
                                        height={footer ? '185px' : '100px'}
                                        style={{
                                            marginBottom: 20,
                                        }}
                                        name="message"
                                        placeholder="Message*"
                                    />

                                    <Flex
                                        justifyContent={
                                            footer ? 'flex-start' : 'center'
                                        }>
                                        {!done ? (
                                            <StyledButton
                                                className="send"
                                                type="submit"
                                                big={
                                                    isMobile ||
                                                    footer !== undefined
                                                }>
                                                Send
                                            </StyledButton>
                                        ) : undefined}
                                        {!footer && isBrowser ? (
                                            <StyledCancel onClick={handleClose}>
                                                Cancel
                                            </StyledCancel>
                                        ) : undefined}
                                    </Flex>
                                </Box>
                            </Flex>
                        </Form>
                    )}
                </Formik>
            )}
        </Box>
    );
};

export default ContactForm;
