import React, { useState } from 'react';
import styled from 'styled-components';
import Measure from 'react-measure';
import { Box } from 'reflexbox';

const SwitchWrapper = styled.div`
    display: inline-flex;
    overflow: hidden;
    label {
        margin-bottom: 0 !important;
    }import { Box } from 'reflexbox';

    position: relative;
    .option-label {
        z-index: 1;
    }
`;

const InnerWrapper = styled.div`
    display: flex;
    align-items: center;
    border: ${({ border }) => border};
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 18px;
    height: 30px;
    padding-right: 2px;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    &:before {
        content: '';
        position: absolute;
        height: calc(100% - 6px);
        width: ${({ selected }) => selected?.offset?.width - 4}px;
        top: 50%;
        left: ${({ selected }) => selected?.offset?.left - 1}px;
        border: 2px solid
            ${({ dark, backgroundColor }) => (dark ? backgroundColor : '#FFF')};
        margin: 0 2px;
        background-color: ${({ theme }) => theme.primary};
        color: #fff;
        transition: all 0.2s ease-in-out;
        border-radius: 18px;
        transition: left 0.1s linear, background 0.1s linear;
        transform: translateY(-50%);
        z-index: 0;
        box-sizing: content-box;
    }
`;

const CaptionLabel = styled.div`
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    padding: 5px 20px;
`;

const Label = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    padding: 5px 20px;
    z-index: 2;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    white-space: nowrap;
    color: ${({ selected, dark }) => (selected || dark ? '#FFF' : 'inherit')};
`;

const SlideSwitch = ({
    value,
    label,
    options,
    onChange,
    dark,
    transparent,
    disabled,
}) => {
    const [nodes, setNodes] = useState([]);

    const bgColor = dark ? '#756E5C' : '#FFF';
    const border = `1px solid ${
        !dark && !transparent ? '#D0D0D0' : 'transparent'
    }`;

    const selectedIndex = disabled
        ? 0
        : options.findIndex(o =>
              typeof o === 'object' ? o.value === value : o === value
          );

    const switchOptions = options.map((o, i) => {
        const isObject = typeof o === 'object';

        return {
            value: isObject ? o.value : o,
            label: (
                <Label
                    selected={selectedIndex === i}
                    dark={dark}
                    disabled={disabled}>
                    {`${isObject ? o.label : o}`}
                </Label>
            ),
        };
    });

    const handleClick = value => {
        if (!disabled) {
            onChange(value);
        }
    };

    const mounted = (bound, offset, idx) => {
        const n = [...nodes];
        n[idx] = { bound, offset };
        setNodes(n);
    };

    const getOption = (option, index) => {
        return (
            <Measure
                key={index}
                bounds
                offset
                onResize={contentRect => {
                    mounted(contentRect.bounds, contentRect.offset, index);
                }}>
                {({ measureRef }) => (
                    <Box
                        ref={measureRef}
                        className="option-label"
                        sx={{ fontFamily: 'Open Sans' }}
                        onClick={() => handleClick(option.value)}>
                        {option.label}
                    </Box>
                )}
            </Measure>
        );
    };

    return (
        <SwitchWrapper>
            {label && <CaptionLabel>{label}</CaptionLabel>}
            <InnerWrapper
                dark={dark}
                border={border}
                disabled={disabled}
                backgroundColor={bgColor}
                selected={nodes[selectedIndex]}>
                {switchOptions.map((o, i) => getOption(o, i))}
            </InnerWrapper>
        </SwitchWrapper>
    );
};

export default SlideSwitch;
