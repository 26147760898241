import React from 'react';
import { MarkerWrapper, PinTitle } from './StyledComponents';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setInfoWindow } from '../../actions';
import { useLocation } from 'react-router';
import { PROPERTY_PAGE_REGEX } from 'components/header';

const PinImage = styled.img`
    transition: all 250ms linear;
    &:hover {
        width: 64px;
    }
`;

const MapMarker = React.memo(props => {
    const { pathname } = useLocation();
    const isPropertyPage = pathname.match(PROPERTY_PAGE_REGEX);

    const dispatch = useDispatch();

    const { type, object = {}, ...rest } = props;

    const { highlightMarker, marker, infoWindow } = useSelector(
        state => state.map
    );
    const isHighlight =
        highlightMarker?.id === object?.id || marker?.id === object?.id;

    let iconPrefix = type;
    if (type === 'place' && object?.types) {
        iconPrefix = object?.types?.length === 1 ? object?.types[0] : 'place';
    }

    let iconUrl =
        type && type !== 'neighborhood'
            ? `${process.env.PUBLIC_URL}/images/mapResources/${
                  isHighlight && object?.propId ? 'selected' : ''
              }${iconPrefix}_map_icon.png`
            : undefined;
    let pinTitle = '';
    if (object?.propId) {
        pinTitle = `ID ${object?.propId}`;
    } else {
        pinTitle = object?.shortTitle || object?.title;
    }

    const handleClick = e => {
        e.stopPropagation();
        if (isPropertyPage && object?.propId) return; //do not popup property info

        /*if (type !== 'neighborhood')*/ dispatch(
            setInfoWindow({ ...object, type })
        );
    };

    return (
        <MarkerWrapper highlight={isHighlight}>
            <>
                <PinTitle onClick={handleClick}>{pinTitle}</PinTitle>
                <PinImage
                    src={iconUrl}
                    width={type !== 'comment' && isHighlight ? 64 : 48}
                    height="auto"
                    style={{
                        position: 'relative',
                        transition: 'all 150ms ease-in-out',
                    }}
                    onClick={handleClick}
                />
            </>
        </MarkerWrapper>
    );
});

export default MapMarker;
