import React from 'react';
import PropertyContent from './PropertyContent';
import LocationContent from './LocationContent';
import { isMobile, isBrowser } from 'react-device-detect';
import PropertyContentMobile from './PropertyContentMobile';
import { Box } from 'reflexbox';
import CommentContent from './CommentContent';

const MarkerContent = props => {
    const isProperty = !!props.propId;
    const isComment = props.type === 'comment';

    return (
        <Box height={isMobile ? '100%' : 'auto'}>
            {isBrowser ? (
                isProperty ? (
                    <PropertyContent {...props} />
                ) : isComment ? (
                    <CommentContent {...props} />
                ) : (
                    <LocationContent {...props} />
                )
            ) : undefined}
            {isMobile ? (
                isProperty ? (
                    <PropertyContentMobile {...props} />
                ) : isComment ? (
                    <CommentContent {...props} />
                ) : (
                    <LocationContent {...props} />
                )
            ) : undefined}
        </Box>
    );
};

export default MarkerContent;
