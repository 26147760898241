import { toggleSidebar } from 'actions/app';
import { theme } from 'index';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Flex } from 'reflexbox';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { ReactComponent as CurrencyIcon } from './icons/currency.svg';
import { ReactComponent as ViewIcon } from './icons/view.svg';
import { ReactComponent as GuidesIcon } from './icons/guides.svg';
import { ReactComponent as AboutIcon } from './icons/about.svg';
import { ReactComponent as ContactIcon } from './icons/contact.svg';
import CurrenciesSwitch from 'components/currency/CurrenciesSwitch';
import { toggleMap } from 'actions/map';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ROUTE_CONST } from 'Routes';
import SubscribeToMailingList from 'elements/SubscribeToMailingList';
import ContactModal from 'components/contactForm/ContactModal';

const Wrapper = styled.div`
    background: #a9a392;
    & .accordion__heading {
        padding: 16px;
        font-size: 14px;
        font-weight: bold;
        border-bottom: 1px solid #e1e1e1;
        color: #707070;

        .accordion__button {
            display: flex;
            align-items: center;

            .material-icons {
                margin-left: auto;
                transition: all 0.3s ease-in-out;
            }
        }
        svg {
            margin-right: 14px;
        }
    }
    .accordion__panel {
        background: #f7f7f7;
        border-bottom: 1px solid #e1e1e1;

        & a {
            display: block;
            padding-left: 62px;
            padding-top: 16px;
            padding-bottom: 16px;
            color: #707070;
        }
    }
    div[aria-expanded='true'] {
        .material-icons {
            transform: rotate(180deg);
        }
    }
`;

export const SideMenu = () => {
    const [isContactOpen, setIsContactOpen] = useState(false);

    const dispatch = useDispatch();

    const close = () => {
        dispatch(toggleSidebar());
    };

    const doToggleMap = () => {
        dispatch(toggleMap());
    };

    const Item = props => <AccordionItemPanel {...props} onClick={close} />;

    return (
        <Wrapper>
            <Flex
                alignItems="center"
                justifyContent="space-between"
                p={3}
                bg="#FFF"
                sx={{ borderBottom: `1px solid ${theme.gold}` }}>
                <Box width={38} height={44}>
                    <NavLink to="/" onClick={close}>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/JR_color.png`}
                            style={{ height: '100%', width: 'auto' }}
                        />
                    </NavLink>
                </Box>
                <Box onClick={close} className="material-icons">
                    close
                </Box>
            </Flex>
            <Flex
                bg="#FFF"
                sx={{ height: 'calc(100svh - 78px)', overflowY: 'auto' }}
                flexDirection="column">
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    p={3}
                    sx={{ borderBottom: '1px solid #E1E1E1' }}>
                    <Flex alignItems="center">
                        <CurrencyIcon />
                        <Box fontSize="14px" fontWeight="bold" ml={3}>
                            Currency
                        </Box>
                    </Flex>
                    <CurrenciesSwitch dark />
                </Flex>
                <Accordion allowMultipleExpanded allowZeroExpanded>
                    <AccordionItem uuid="view">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <ViewIcon />
                                View
                                <Box className="material-icons">
                                    arrow_drop_down
                                </Box>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <Item>
                            <a onClick={doToggleMap}>Map</a>
                        </Item>
                        <Item>
                            <NavLink
                                activeClassName="selected"
                                to={`${ROUTE_CONST.PROPERTIES}/?status=available,soon`}>
                                Properties
                            </NavLink>
                        </Item>
                    </AccordionItem>
                    <AccordionItem uuid="guides">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <GuidesIcon />
                                Guides
                                <Box className="material-icons">
                                    arrow_drop_down
                                </Box>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <Item>
                            <NavLink
                                activeClassName="selected"
                                to={`${ROUTE_CONST.NEIGHBORHOODS}`}>
                                Neighborhoods Guide
                            </NavLink>
                        </Item>
                        <Item>
                            <NavLink
                                activeClassName="selected"
                                to={`${ROUTE_CONST.ARTICLES}`}>
                                Articles
                            </NavLink>
                        </Item>
                        <Item>
                            <NavLink
                                activeClassName="selected"
                                to={`${ROUTE_CONST.QA}`}>
                                Questions & Answers
                            </NavLink>
                        </Item>
                    </AccordionItem>
                    <AccordionItem uuid="about">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <AboutIcon />
                                About
                                <Box className="material-icons">
                                    arrow_drop_down
                                </Box>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <Item>
                            <NavLink
                                activeClassName="selected"
                                to={`${ROUTE_CONST.BRIEF_INTRO}`}>
                                Brief Intro
                            </NavLink>
                        </Item>
                        <Item>
                            <NavLink
                                activeClassName="selected"
                                to={`${ROUTE_CONST.PROFILE}`}>
                                Agency Profile
                            </NavLink>
                        </Item>
                        <Item>
                            <NavLink
                                activeClassName="selected"
                                to={`${ROUTE_CONST.TESTIMONIALS}`}>
                                Testimonials
                            </NavLink>
                        </Item>
                        <Item>
                            <NavLink
                                activeClassName="selected"
                                to={`${ROUTE_CONST.TRUSTED_BY}`}>
                                Trusted By
                            </NavLink>
                        </Item>
                        <Item>
                            <NavLink
                                activeClassName="selected"
                                to={`${ROUTE_CONST.OUR_SERVICE}`}>
                                Our Services
                            </NavLink>
                        </Item>
                        <Item>
                            <NavLink
                                activeClassName="selected"
                                to={`${ROUTE_CONST.ABOUT_CL}`}>
                                Custom Listing
                            </NavLink>
                        </Item>
                        <Item>
                            <NavLink
                                activeClassName="selected"
                                to={`${ROUTE_CONST.HOW_WE_WORK}`}>
                                How We Work
                            </NavLink>
                        </Item>
                        <Item>
                            <NavLink
                                activeClassName="selected"
                                to={`${ROUTE_CONST.WHY_WORK_WITH_US}`}>
                                Work With Us
                            </NavLink>
                        </Item>
                    </AccordionItem>
                    <AccordionItem uuid="contact">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <ContactIcon />
                                Contact
                                <Box className="material-icons">
                                    arrow_drop_down
                                </Box>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <Item>
                            <NavLink
                                activeClassName="selected"
                                to={`${ROUTE_CONST.WELCOME}`}>
                                Welcome
                            </NavLink>
                        </Item>
                        <Item>
                            <a
                                target="_blank"
                                href={
                                    'https://forms.ejrealty.net/JerusalemRealty/form/PropertyRequiredFormDisattachedForm/formperma/CWHu6AxUL_qDOqdlLvZ_iqQYEcueceOoS6Hoj-v_tkQ'
                                }>
                                Property Required Form
                            </a>
                        </Item>
                        <Item>
                            <Box
                                onClick={() => setIsContactOpen(true)}
                                p={3}
                                pl={60}>
                                Contact us
                            </Box>
                        </Item>
                    </AccordionItem>
                </Accordion>
                <Box bg="#A9A392" color="#FFF" px={50} py={30} flexGrow={1}>
                    <Box fontSize="14px" fontWeight="bold" mb={2}>
                        Jerusalem Realty
                    </Box>
                    <Box mb={4} pl={2}>
                        <Box>HaHayil St. 90</Box>
                        <Box>French Hill, 9789118</Box>
                        <Box>Jerusalem, Israel</Box>
                        <Box>Email: jrealty@jrealty.net</Box>
                        <Box>Tel: +972 (0)2 656 4322</Box>
                        <Box>Fax: +972 (0)2 591 6092</Box>
                    </Box>
                    <Box fontSize="14px" fontWeight="bold" mb={3}>
                        Subscribe to our Newsletter
                    </Box>
                    <SubscribeToMailingList />
                </Box>
            </Flex>
            <ContactModal open={isContactOpen} label={' '} />
        </Wrapper>
    );
};
