import * as constants from 'constants';
import { TOGGLE_INTRO_MESSAGE } from '../../constants';

const initialState = {
    object: {
        name: '',
        recipients: [],
        properties: [],
        neighborhoods: [],
        messages: [],
        places: [],
        notes: [],
        messagesEnabled: true,
    },
    messagesFullscreen: false,
    filtered: [],
    filters: {
        neighborhood: [],
        status: [],
        recommended: false,
        relevance: [],
        visible: [{ id: 1, title: 'Visible' }],
    },
};
const customMap = (state = initialState, action) => {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case constants.GET_CUSTOM_MAP_SUCCESS:
            newState.object = action.customMap;
            newState.filtered = action.customMap.properties;
            return newState;
        case constants.SAVE_CUSTOM_MAP_SUCCESS:
            newState.object = action.customMap;
            return newState;
        case constants.RESET_CUSTOM_MAP_FILTERS_APPLIED:
            newState.filters = {
                neighborhood: [],
                status: [],
                recommended: false,
                relevance: [],
                visible: [{ id: 1, title: 'Visible' }],
            };

            return newState;
        case constants.CUSTOM_MAP_FILTER_APPLY:
            newState.filters[action.name] = action.value;
            return newState;
        case constants.FILTER_CUSTOM_MAP_PROPERTIES:
            newState.filtered = action.filtered;
            return newState;
        case constants.RESET_CUSTOM_MAP:
            return initialState;
        case constants.TOGGLE_MESSAGES_FULLSCREEN:
            newState.messagesFullscreen = !newState.messagesFullscreen;
            return newState;
        case constants.TOGGLE_INTRO_MESSAGE:
            newState.object.showIntroMessage =
                !newState.object.showIntroMessage;
            return newState;
        default:
            return newState;
    }
};

export default customMap;
