import React from 'react';
import styled, { css } from 'styled-components';
import uuid from 'react-uuid';

const CheckInput = styled.input`
    position: absolute;
    height: 18px;
    width: 18px;
    border-radius: 4px;
    left: 0px;
    top: 0px;

    &:focus {
        outline: none;
        box-shadow: 2px solid #deca71;
    }
`;

const CheckOption = styled.label`
    display: inline-block;
    position: relative;
    font-size: 14px;
    user-select: none;
    width: auto;
    min-height: 20px;
    margin-bottom: 0px;

    ${({ slim }) =>
        slim &&
        css`
            &,
            & > label {
                margin-bottom: 0 !important;
            }
        `}
    & input {
        opacity: 0.0001;

        & + label {
            &::before {
                content: '';
                height: 18px;
                width: 18px;
                cursor: ${({ disabled }) =>
                    disabled ? 'not-allowed' : 'pointer'};
                border-radius: 4px;
                border: 1px solid #ccc;
                background: #fff;
                box-sizing: border-box;
                display: inline-block;
                position: absolute;
                top: 2px;
                left: 0px;
            }
        }

        &:checked + label::before {
            background: ${({ styled }) => (styled ? '#fed630' : '#FFF')};
            border-color: ${({ styled }) => (styled ? '#fed630' : '#676767')};
        }
        &:checked + label::after {
            content: '';
            cursor: pointer;
            display: block;
            transform: rotate(45deg) translateY(-50%) translateX(-50%);
            transform-origin: center;
            height: 10px;
            width: 5px;
            border-bottom: 2px solid #646464;
            border-right: 2px solid #646464;
            box-sizing: border-box;
            position: absolute;
            top: 9px;
            left: 5px;
            margin-top: 1px;
        }
        &:focus + label::before {
            box-shadow: 2px solid khaki;
        }
    }
    label {
        outline: none;
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
        text-align: left;
    }
    &:focus {
        outline: none;
    }
`;

export const Checkbox = ({
    id = uuid(),
    label,
    value = false,
    onChange,
    error,
    isChecked,

    ...rest
}) => {
    const labelStyle = {
        paddingLeft: `25px`,
        paddingRight: '10px',
        display: 'inline-block',
        verticalAlign: 'text-top',
        ...rest.labelStyles,
    };

    return (
        <CheckOption {...rest}>
            <CheckInput
                type="checkbox"
                checked={value}
                id={id}
                onChange={() => onChange(!value)}
                ref={rest.ref}
                tabIndex={rest.tabIndex}
                disabled={rest.disabled}
            />
            <label htmlFor={id} style={labelStyle}>
                {label}
            </label>
        </CheckOption>
    );
};

export default Checkbox;
