import { ROUTE_CONST } from 'Routes';
import { toggleMap } from 'actions/map';
import CurrenciesSwitch from 'components/currency/CurrenciesSwitch';
import { theme } from 'index';
import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Box, Flex } from 'reflexbox';
import useScreen from 'utils/screen';
import ContactModal from '../contactForm/ContactModal';
import HeaderMenu from './HeaderMenu';
import { HeaderLink, StyledHeader } from './StyledComponents';
import { toggleSidebar } from 'actions/app';
import { FloatingButton } from 'components/FloatingButton/index';

export const PROPERTY_PAGE_REGEX = /^\/properties\/([^/]+)(?:\/.*)?$/;
export const PROPERTIES_PAGE_REGEX = /^\/properties\/(?:\?.*)?$/;
export const CUSTOM_LISTING_PAGE_REGEX = /^\/CustomListings\/([^/]+)(?:\/.*)?$/;
export const NEIGHBORHOOD_PAGE_REGEX = /^\/neighborhoods\/([^/]+)(?:\/.*)?$/;
export const NEIGHBORHOODS_PAGE_REGEX = /^\/neighborhoods\/?$/;

const Header = props => {
    const {
        neighborhood,
        users: { user },
        customMap: { object: customMap },
        property,
        map: { fullscreen },
    } = useSelector(state => state);
    const isMobile = useScreen();

    const dispatch = useDispatch();

    const {
        location: { pathname },
    } = props;

    const isHomepage = pathname === '/';
    const isPropertyPage = pathname.match(PROPERTY_PAGE_REGEX);
    const isPropertiesPage = pathname.match(PROPERTIES_PAGE_REGEX);
    const isCustomListingPage = pathname.match(CUSTOM_LISTING_PAGE_REGEX);
    const isNeighborhoodPage = pathname.match(NEIGHBORHOOD_PAGE_REGEX);
    const isNeighborhoodsPage = pathname.match(NEIGHBORHOODS_PAGE_REGEX);

    const [solid, setSolid] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY < 450 && solid) {
                setSolid(false);
            }
            if (currentScrollY > 450 && !solid) {
                setSolid(true);
            }
        };
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [solid]);

    const doToggleMap = () => {
        dispatch(toggleMap());
    };

    const doToggleSidemap = () => {
        dispatch(toggleSidebar());
    };

    const transparent = !solid && isHomepage;

    const Title = () => {
        if (isPropertyPage || isPropertiesPage) {
            return (
                <Box
                    fontSize="14px"
                    fontWeight="700"
                    color="#606060"
                    lineHeight="18px"
                    pr={1}>
                    {isPropertiesPage ? 'Property listing' : property.title}
                </Box>
            );
        }
        if (isNeighborhoodsPage || isNeighborhoodPage) {
            return (
                <Box
                    fontSize="14px"
                    fontWeight="700"
                    color="#606060"
                    lineHeight="18px"
                    pr={1}>
                    {isNeighborhoodsPage ? 'Neighborhoods' : neighborhood.title}
                </Box>
            );
        }
        if (isCustomListingPage) {
            return (
                <Box flexGrow={1}>
                    {customMap.title ? (
                        <Box fontSize="14px" color="#606060" lineHeight="18px">
                            Custom Listing for
                        </Box>
                    ) : undefined}
                    <Box
                        fontSize="16px"
                        fontWeight="700"
                        lineHeight="18px"
                        color={theme.gold}>
                        {customMap.title}
                    </Box>
                </Box>
            );
        }
    };

    return (
        <StyledHeader
            transparent={transparent}
            fullscreen={fullscreen}
            noShadow={isMobile && isPropertiesPage}
            withBottomBorder={isMobile && isPropertiesPage}>
            <Flex
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                px={[3, 0]}
                py="18px">
                {isMobile &&
                    (isCustomListingPage ||
                        isPropertyPage ||
                        isNeighborhoodPage ||
                        isNeighborhoodsPage) && (
                        <Box
                            sx={{
                                maxWidth: 'calc(100svw - 100px)',
                                flexGrow: 1,
                            }}>
                            <Title />
                        </Box>
                    )}
                <Box
                    as={NavLink}
                    to="/"
                    mx={[0, 4]}
                    ml={[0, 4]}
                    height={[38, 44]}>
                    <img
                        src={`${process.env.PUBLIC_URL}/images/JR_${
                            transparent ? 'white' : 'color'
                        }.png`}
                        style={{ height: '100%', flexShrink: 0 }}
                    />
                </Box>
                {isMobile &&
                    !isHomepage &&
                    !isCustomListingPage &&
                    !isPropertyPage &&
                    !isNeighborhoodPage &&
                    !isNeighborhoodsPage && (
                        <Box px={3}>
                            <Title />
                        </Box>
                    )}
                <BrowserView>
                    <Flex>
                        <HeaderMenu
                            label="View"
                            transparent={transparent}
                            pl={3}>
                            <HeaderLink as="a" onClick={doToggleMap}>
                                Map
                            </HeaderLink>
                            <HeaderLink to="/properties/?status=available,soon">
                                Properties
                            </HeaderLink>
                        </HeaderMenu>
                        <HeaderMenu label="Guides" transparent={transparent}>
                            <HeaderLink to={ROUTE_CONST.NEIGHBORHOODS}>
                                Neighborhoods
                            </HeaderLink>
                            <HeaderLink to={ROUTE_CONST.ARTICLES}>
                                Articles
                            </HeaderLink>
                            <HeaderLink to={ROUTE_CONST.QA}>
                                Questions & Answers
                            </HeaderLink>
                            {/* <HeaderLink>New Arrivals Guide</HeaderLink> */}
                        </HeaderMenu>
                        <HeaderMenu label="About" transparent={transparent}>
                            <HeaderLink to={ROUTE_CONST.BRIEF_INTRO}>
                                Brief Intro
                            </HeaderLink>
                            <HeaderLink to={ROUTE_CONST.PROFILE}>
                                Agency Profile
                            </HeaderLink>
                            <HeaderLink to={ROUTE_CONST.TESTIMONIALS}>
                                Testimonials
                            </HeaderLink>
                            <HeaderLink to={ROUTE_CONST.TRUSTED_BY}>
                                Trusted by
                            </HeaderLink>
                            <HeaderLink to={ROUTE_CONST.PRICING}>
                                Pricing Policy
                            </HeaderLink>
                            <HeaderLink to={ROUTE_CONST.OUR_SERVICE}>
                                Our Services
                            </HeaderLink>
                            <HeaderLink to={ROUTE_CONST.ABOUT_CL}>
                                Custom Listing
                            </HeaderLink>
                            <HeaderLink to={ROUTE_CONST.HOW_WE_WORK}>
                                How We Work
                            </HeaderLink>
                            <HeaderLink to={ROUTE_CONST.WHY_WORK_WITH_US}>
                                Work with us
                            </HeaderLink>
                        </HeaderMenu>
                        <Box mr={[2, 4]} mt={2} ml={[0, 4]} minWidth={100}>
                            <ContactModal light={transparent} bordered />
                        </Box>
                        {user && user.isAdmin ? (
                            <Box mt={2} mx={4}>
                                <HeaderLink
                                    to={ROUTE_CONST.ADMIN}
                                    transparent={transparent ? 1 : 0}>
                                    Admin
                                </HeaderLink>
                            </Box>
                        ) : undefined}
                        {!isMobile ? (
                            <Box mx="20px" my="5px">
                                <CurrenciesSwitch transparent={transparent} />
                            </Box>
                        ) : undefined}
                    </Flex>
                </BrowserView>
                <MobileView>
                    <Box
                        className="material-icons"
                        style={{ color: transparent ? '#FFF' : '#919191FF' }}
                        onClick={doToggleSidemap}>
                        menu
                    </Box>
                </MobileView>
            </Flex>
        </StyledHeader>
    );
};
export default withRouter(Header);
