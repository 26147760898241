import React from 'react';
import { NavLink } from 'react-router-dom';
import SubscribeToMailingList from 'elements/SubscribeToMailingList';
import CurrenciesSwitch from 'components/currency/CurrenciesSwitch';
import { Flex, Box } from 'reflexbox';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { toggleMap } from 'actions';
import { ROUTE_CONST } from '../Routes';
import { BrowserView, MobileView } from 'react-device-detect';
import { ReactComponent as FooterLogo } from './logo_footer.svg';

const MenuHeader = styled.div`
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    color: #fff;
    margin-bottom: 25px;
`;
const FooterLink = styled(NavLink)`
    font-size: 14px;
    font-weight: 500;
    line-height: 29px;
    color: #ffffff80 !important;

    display: table;
    line-height: 36px;
    &:hover {
        text-decoration: none;
        color: #fff !important;
    }
`;

export const MobileFooter = () => (
    <Box as="footer" bg="#aaa390" py={3}>
        <Flex alignItems="center" justifyContent="center">
            <FooterLogo />
            <Box
                px={3}
                color="rgba(255, 255, 255, 0.70)"
                fontSize="12px"
                fontWeight="600">
                © 2025 Jerusalem Realty. All Rights Reserved.{' '}
            </Box>
        </Flex>
    </Box>
);

const Footer = props => {
    let year = new Date();
    year = year.getFullYear();

    const dispatch = useDispatch();

    const doToggleMap = () => {
        dispatch(toggleMap());
    };

    return (
        <>
            <BrowserView>
                <footer className="footer">
                    <Flex px={30} flexWrap="wrap">
                        <Flex width={[1, 1 / 2]} flexWrap="wrap">
                            <Box width={[1 / 2, 1 / 3]}>
                                <MenuHeader>View</MenuHeader>
                                <FooterLink to="" onClick={doToggleMap}>
                                    Map
                                </FooterLink>
                                <FooterLink
                                    to="/properties/?status=available,soon"
                                    activeClassName="active">
                                    Properties
                                </FooterLink>
                            </Box>
                            <Flex width={[1 / 2, 2 / 3]} flexWrap="wrap">
                                <Box width={1}>
                                    <MenuHeader>About</MenuHeader>
                                </Box>
                                <Box width={[1, 1 / 2]}>
                                    <FooterLink
                                        to={ROUTE_CONST.PROFILE}
                                        activeClassName="active">
                                        Agency Profile
                                    </FooterLink>
                                    <FooterLink
                                        to={ROUTE_CONST.BRIEF_INTRO}
                                        activeClassName="active">
                                        Introduction
                                    </FooterLink>
                                    <FooterLink
                                        to={ROUTE_CONST.TESTIMONIALS}
                                        activeClassName="active">
                                        Testimonials
                                    </FooterLink>
                                    <FooterLink
                                        to={ROUTE_CONST.TRUSTED_BY}
                                        activeClassName="active">
                                        Trusted by
                                    </FooterLink>
                                    <FooterLink
                                        to={ROUTE_CONST.PRICING}
                                        activeClassName="active">
                                        Pricing Policy
                                    </FooterLink>
                                </Box>
                                <Box width={[1, 1 / 2]}>
                                    <FooterLink
                                        to={ROUTE_CONST.OUR_SERVICE}
                                        activeClassName="active">
                                        Our Services
                                    </FooterLink>
                                    <FooterLink
                                        to={ROUTE_CONST.HOW_WE_WORK}
                                        activeClassName="active">
                                        How We Work
                                    </FooterLink>
                                    <FooterLink
                                        to={ROUTE_CONST.ABOUT_CL}
                                        activeClassName="active">
                                        About Custom Listing
                                    </FooterLink>
                                    <FooterLink
                                        to={ROUTE_CONST.WHY_WORK_WITH_US}
                                        activeClassName="active">
                                        Work with us
                                    </FooterLink>
                                </Box>
                            </Flex>
                        </Flex>
                        <Flex width={[1, 1 / 2]} mt={[30, 0]}>
                            <Box width={[1 / 2]} pl={[0, 100]}>
                                <MenuHeader>Currency</MenuHeader>
                                <CurrenciesSwitch dark />
                            </Box>
                            <Box width={[1 / 2]} mb={[30]}>
                                <MenuHeader>Subscribe</MenuHeader>
                                <SubscribeToMailingList />
                            </Box>
                        </Flex>

                        <Flex
                            mb={25}
                            mt={20}
                            width={[1, 1 / 2]}
                            flexShrink={[0]}
                            flexWrap="wrap">
                            <Box width={[1, 1 / 3]}>
                                <MenuHeader>Contact us</MenuHeader>
                            </Box>
                            <Box
                                width={[1 / 2, 1 / 3]}
                                color="#FFF"
                                fontSize="14px"
                                fontWeight="500"
                                lineHeight="26px">
                                <Box>
                                    HaHayil St. 90
                                    <br />
                                    French Hill, 9789118
                                    <br />
                                    Jerusalem, Israel
                                </Box>
                            </Box>
                            <Box
                                width={[1 / 2, 1 / 3]}
                                color="#FFF"
                                fontSize="14px"
                                fontWeight="500"
                                lineHeight="26px">
                                <Box>
                                    Email: jrealty@jrealty.net
                                    <br />
                                    Tel: +972 (0)2 656 4322
                                    <br />
                                    Fax: +972 (0)2 591 6092
                                </Box>
                            </Box>
                        </Flex>
                    </Flex>

                    <Box
                        px={30}
                        py={35}
                        bg="#756E5C33"
                        fontSize="14px"
                        fontWeight="500"
                        style={{ borderTop: '1px solid #FFFFFF33' }}
                        color="#FFFFFF80">
                        <label className="copyright">
                            &copy; {year} Jerusalem Realty. All Rights Reserved.
                        </label>
                        <div className="social_NavLink pull-right">
                            {/*
                            <a href="https://www.facebook.com/jrealty.net/" target="_blank" className="icon-facebook"></a>
                            <a href="/" className="icon-twitter"></a>
                            <a href="/" className="icon-NavLinkedin"></a>
                    */}
                        </div>
                    </Box>
                </footer>
            </BrowserView>
            {/* <MobileView>
                <Box as="footer" bg="#aaa390" py={3}>
                    <Flex alignItems="center" justifyContent="center">
                        <FooterLogo />
                        <Box
                            px={3}
                            color="rgba(255, 255, 255, 0.70)"
                            fontSize="15px"
                            fontWeight="600">
                            Jerusalem Realty
                        </Box>
                    </Flex>
                </Box>
            </MobileView> */}
        </>
    );
};

export default Footer;
