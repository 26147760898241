import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense, useEffect } from 'react';
import './styles/main.scss';
import { getArticleTags, getCurrencies, getCurrentUser } from 'actions';
import AppLayout from 'layouts/AppLayout';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import Routes from './Routes';
import { FloatingButton } from './components/FloatingButton/index';
import Map from './components/map';
import Loading from './elements/Loading';
import { Box } from 'reflexbox';

const HEADLESS_PAGES = ['/whyjr', '/retal_east_jerusalem'];

// eslint-disable-next-line react/function-component-definition
const App = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (window.innerWidth <= 960) {
            // eslint-disable-next-line global-require
            require('styles/mobile.scss');
        }
        dispatch(getCurrentUser());
        dispatch(getCurrencies());
        dispatch(getArticleTags());
    }, []);

    const { pathname } = useLocation();

    const isHeadless = HEADLESS_PAGES.find(page => pathname.includes(page));
    const isAdminPage = pathname.match(/^\/admin(\/.*)?$/);

    return (
        <Suspense fallback={<div className="suspense">loading....</div>}>
            <AppLayout headless={isHeadless}>
                <Routes store={props.store} />
                <Loading />
                <Map />
                <BrowserView>{!isAdminPage && <FloatingButton />}</BrowserView>
                <MobileView>
                    <Box id="mobile-popup" />
                </MobileView>
            </AppLayout>
        </Suspense>
    );
};

export default App;
