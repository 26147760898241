import Price from 'components/currency/Price';
import React from 'react';
import { Box, Flex } from 'reflexbox';
import styled from 'styled-components';
import Furniture from '../Furniture';
import QualityStars from '../QualityStars';
import Availability from './Availability';
// import TileCustomMapMessage from './TileCustomMapMessage';
import {
    CUSTOM_LISTING_PAGE_REGEX,
    PROPERTY_PAGE_REGEX,
} from 'components/header';
import { theme } from 'index';
import { isBrowser, isMobile, MobileView } from 'react-device-detect';
import { useLocation } from 'react-router';
import Recommended from './Recommended';
import { floorConst, furnitureConst } from 'components/property/constants';
import { Flagged } from '../Details';

const Wrapper = styled(Box)`
    padding: ${({ isList }) => (isList ? '0px' : '0px 20px')};
    margin: ${({ isList }) => (isList ? '20px 0px' : '0px')};
    border-radius: ${({ isList, allCorners }) =>
        isList ? '0px' : allCorners ? '20px' : '0px 0px 20px 20px'};
    background: ${({ isList }) => (isList ? 'transparent' : '#F2F2F2')};
`;

const Specs = styled(Flex)`
    padding: ${({ isList }) => (isList ? '10px 45px' : '10px 0px')};
    border-radius: ${({ isList }) => (isList ? '50px' : '0px')};
    background: ${({ isList }) => (isList ? '#F2F2F2' : 'transparent')};
`;

const Specs2 = styled(Flex)`
    padding-left: ${({ isList }) => (isList ? '5px' : '0px')};
    padding-right: ${({ isList }) => (isList ? '30px' : '0px')};
    padding-bottom: ${({ isList }) => (isList ? '0px' : '10px')};
    & > div {
        padding-right: 10px;
        margin-right: 10px;
        position: relative;
    }
    & > div:not(:last-child):after {
        content: '';
        position: absolute;
        display: inline-block;
        width: 3px;
        height: 3px;
        border-radius: 4px;
        background: #555;
        right: 0px;
        top: 50%;
    }
`;

const Value = styled.div`
    font-size: ${({ isList, isMobile }) =>
        isList || isMobile ? '16px' : '14px'};
    line-height: ${({ isList }) => (isList ? '24px' : '22px')};
    font-weight: 600;
`;
export const Label = styled.div`
    color: ${({ horizontal }) => (horizontal ? '#666666' : '#acacac')};
    font-size: ${({ isList, horizontal, isMobile }) =>
        isList || horizontal || isMobile ? '13px' : '11px'};
    margin-left: ${({ horizontal }) => (horizontal ? '10px' : '0px')};
    white-space: nowrap;
`;
const StyledPrice = styled(Box)`
    color: ${({ striked }) => (striked ? '#E33030' : '#19a99d')};
    font-size: 17px;
    line-height: 20px;
    font-weight: bold;
    text-decoration: ${({ striked }) => (striked ? 'line-through' : 'none')};
`;
const Additional = styled.div`
    font-size: ${({ isList }) => (isList ? '13px' : '11px')};
    line-height: ${({ isList }) => (isList ? '22px' : '16px')};
    color: ${({ isList }) => (isList ? '#555555' : '#888888')};
    font-weight: bold;
    text-transform: capitalize;
`;

const MobileFeatureAttrib = ({ caption, value, ...rest }) => (
    <Flex alignItems="center" py={2} {...rest}>
        <Box color="#AFAFAF" fontWeight="600" width="100px">
            {caption}
        </Box>
        <Box color={theme.gold} fontWeight="600">
            {value}
        </Box>
    </Flex>
);

const getAttribute = (val, title, isList) => {
    if (val) {
        return (
            <Box mr={4}>
                <Value isList={isList} isMobile={isMobile}>
                    {val}
                </Value>
                <Label isList={isList} isMobile={isMobile}>
                    {title}
                </Label>
            </Box>
        );
    }
};

function Attributes(props) {
    const {
        bedrooms,
        bathrooms,
        proptypeLabel,
        neighborhood,
        size,
        floors,
        apartments,
        furnitureStatus,
        durations = [],
        quality,
        price,
        priceComment,
        negotiable,
        discountedPrice,
        currency = 'USD',
        status,
        availableOn,
        isList,
        recommended,
        exclusive,
        propertyInBuilding,
        propsInBuilding,
        noFurniture,
        noPrice,
        floor,
        note,
    } = props;

    const { pathname } = useLocation();
    const isCustomListing = pathname.match(CUSTOM_LISTING_PAGE_REGEX);
    const isPropertyPage = pathname.match(PROPERTY_PAGE_REGEX);

    const isBuilding = propsInBuilding?.length;

    const durationString = () => {
        let str = '';
        durations.map((s, i) => {
            str += s;
            if (i < durations.length - 1) str += ', ';
        });
        return str;
    };

    return (
        <Box>
            <Wrapper isList={isList} width="100%">
                <Specs isList={isList} alignItems="flex-start">
                    {isList && proptypeLabel
                        ? getAttribute(proptypeLabel, 'Property type', isList)
                        : undefined}
                    {getAttribute(bedrooms, 'Beds', isList)}
                    {getAttribute(bathrooms, 'Baths', isList)}
                    {getAttribute(size, 'Sqr', isList)}
                    {getAttribute(floors, 'Floors', isList)}
                    {!isMobile &&
                        getAttribute(apartments, 'Apartments', isList)}
                    {!propertyInBuilding &&
                    neighborhood &&
                    (isList || isPropertyPage)
                        ? getAttribute(
                              neighborhood.title,
                              'Neighborhood',
                              isList
                          )
                        : undefined}
                    {propertyInBuilding || (!isBuilding && !isPropertyPage) ? (
                        <Availability
                            status={status}
                            availableOn={availableOn}
                            isList={isList}
                        />
                    ) : undefined}
                    <Flex
                        ml="auto"
                        flexDirection="column"
                        alignItems="flex-end">
                        {!noPrice && price ? (
                            <StyledPrice striked={discountedPrice}>
                                <Price amount={price} currency={currency} />
                                /m
                            </StyledPrice>
                        ) : undefined}
                        {discountedPrice ? (
                            <StyledPrice>
                                <Price
                                    amount={discountedPrice}
                                    currency={currency}
                                />
                                /m
                            </StyledPrice>
                        ) : undefined}
                        {negotiable ? (
                            <Box
                                fontSize="0.9em"
                                mt="2px"
                                color="#acacac"
                                style={{ textAlign: 'right' }}>
                                Negotiable
                            </Box>
                        ) : undefined}
                    </Flex>
                </Specs>
                <Specs2 mt={isList ? 4 : 2} isList={isList} alignItems="center">
                    {furnitureStatus && !isBuilding && !noFurniture ? (
                        <Additional isList={isList}>
                            {Furniture(furnitureStatus)}
                        </Additional>
                    ) : undefined}
                    {quality && !isBuilding ? (
                        <Additional isList={isList}>
                            <QualityStars stars={quality} />
                        </Additional>
                    ) : undefined}
                    {durations.length && !isBuilding
                        ? durations.map(d => (
                              <Flex key={d}>
                                  <Additional isList={isList} key={d}>
                                      {d}
                                  </Additional>
                              </Flex>
                          ))
                        : undefined}
                </Specs2>
                {recommended && !isPropertyPage ? <Recommended /> : undefined}
            </Wrapper>
            {isPropertyPage && isMobile && !propertyInBuilding ? (
                <Box>
                    <Box fontSize="14px" fontWeight="700" p={3}>
                        More details
                    </Box>
                    <Box
                        bg="#F7F7F7"
                        p="20px 20px"
                        style={{ borderRadius: '20px' }}>
                        {!isBuilding && furnitureStatus ? (
                            <MobileFeatureAttrib
                                caption="Furniture"
                                value={furnitureConst[furnitureStatus]}
                            />
                        ) : undefined}
                        {!isBuilding && floor !== undefined ? (
                            <MobileFeatureAttrib
                                caption="Floor"
                                value={floorConst[floor]}
                            />
                        ) : undefined}
                        {isBuilding && floors ? (
                            <MobileFeatureAttrib
                                caption="Floors"
                                value={floors}
                            />
                        ) : undefined}
                        {isBuilding && apartments ? (
                            <MobileFeatureAttrib
                                caption="Apartments"
                                value={apartments}
                            />
                        ) : undefined}
                        {quality ? (
                            <MobileFeatureAttrib
                                caption="Quality"
                                value={<QualityStars stars={quality} />}
                            />
                        ) : undefined}
                        {durations ? (
                            <MobileFeatureAttrib
                                caption="Duration"
                                value={durationString()}
                                style={{
                                    textTransform: 'capitalize',
                                }}
                            />
                        ) : undefined}
                    </Box>
                </Box>
            ) : undefined}

            <Flex>
                {recommended && isPropertyPage ? (
                    <Flagged my={10} ml={10}>
                        <Box fontSize="12px" lineHeight="15px">
                            HIGHLY
                        </Box>
                        <Box fontSize="12px" lineHeight="15px">
                            RECOMMENDED
                        </Box>
                    </Flagged>
                ) : undefined}
                {exclusive && isPropertyPage ? (
                    <Flagged my={10} ml={30}>
                        <Flex>
                            <Box className="material-icons">grade</Box>
                            <Box fontSize="16px">EXCLUSIVE</Box>
                        </Flex>
                    </Flagged>
                ) : undefined}
            </Flex>
        </Box>
    );
}

export default Attributes;
