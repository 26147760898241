import {
    SAVE_BANNER_IMAGE,
    FETCH_BANNER_IMAGE_SUCCESS,
    TOGGLE_MAP,
    SET_MAP_MARKER,
    TOGGLE_SIDEBAR,
} from '../constants';
import Axios from 'axios';

export function setBusy() {
    return {
        type: 'LOADING',
    };
}
export function setIdle() {
    return {
        type: 'IDLE',
    };
}

export async function fetchBannerImage() {
    const bannerImage = await Axios.get('/banneredit');

    return {
        type: FETCH_BANNER_IMAGE_SUCCESS,
        url: bannerImage.data,
    };
}

export function saveBannerImage(url) {
    return {
        type: SAVE_BANNER_IMAGE,
        url: url,
    };
}

export function toggleSidebar() {
    return {
        type: TOGGLE_SIDEBAR,
    };
}
