import { updateRelevance } from 'actions/customMaps';
import { theme } from 'index';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'reflexbox';

const OPTIONS = [
    'Highly interesting',
    'Potential',
    'Worth visiting',
    'Not interesting',
];
// THERE IS A SIMILAR LIST IN SAGA
const InterestInProperty = ({ propertyId }) => {
    const {
        customMap: {
            object: { id, propertiesMeta },
        },
    } = useSelector(state => state);

    const dispatch = useDispatch();

    const propMeta = propertiesMeta.find(p => p.propertyId === propertyId);

    const [categories, setCategories] = useState(propMeta?.categories || []);

    const hasCategory = cat => {
        return categories?.indexOf(cat) >= 0;
    };

    const handleClick = (value, i) => {
        const list = categories.filter(item => !OPTIONS.includes(item)); //start with a clean list
        setCategories([...list, value]);
        dispatch(updateRelevance(value, propertyId));
    };

    const getItem = (value, i) => {
        const selected = hasCategory(value);

        return (
            <Box
                key={i}
                width={1 / 2}
                height={32}
                px={2}
                my={2}
                onClick={() => handleClick(value, i)}
                sx={{ cursor: 'pointer' }}>
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                    width="100%"
                    sx={{
                        borderRadius: 50,
                        border: `1px solid ${selected ? '#FFCC07' : '#AFAFAF'}`,
                        color: '#606060',
                        background: selected ? '#FFCC07' : '#FFF',
                    }}>
                    {selected ? (
                        <Box
                            className="material-icons"
                            fontSize="20px"
                            mr={2}
                            ml={-20}>
                            check
                        </Box>
                    ) : undefined}
                    <Box>{value}</Box>
                </Flex>
            </Box>
        );
    };

    return (
        <Box pt={3} pb={5}>
            <Box fontWeight="600" mb={3} textAlign="left">
                Interest in this property
            </Box>
            <Flex flexWrap="wrap">{OPTIONS.map((o, i) => getItem(o, i))}</Flex>
        </Box>
    );
};

export default InterestInProperty;
